import Head from 'next/head';
import frioS1 from 'public/Frio S1.jpg';
import Image from 'next/image';
import React, { ReactNode } from 'react';
import frioLogo from 'public/frio.svg';
import { default as NextLink } from 'next/link';
import { Link } from '../design-system';

interface UserSignupLayoutProps {
  title?: string;
  children: ReactNode;
  headerChildren?: ReactNode;
}

const UserSignupLayout = ({ title, children, headerChildren }: UserSignupLayoutProps) => {
  return (
    <>
      <Head>
        <title>{title || 'Dashboard'}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <div className="flex flex-col w-full h-dvh p-8" data-cy="user-signup-layout">
        <div className="flex justify-between mb-10 items-start h-fit">
          <Link href="https://www.frio.co/" as={NextLink}>
            <Image
              width={128}
              height={128}
              src={frioLogo}
              alt="Frio Logo"
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          </Link>
          {headerChildren}
        </div>
        <div className="flex grow items-center gap-32">
          <div className="w-full lg:w-1/2 flex justify-center lg:justify-end">
            <div className="lg:max-w-96">{children}</div>
          </div>
          <div className="hidden lg:flex lg:w-1/2 justify-start">
            <Image
              src={frioS1}
              alt="Frio S1"
              style={{
                maxWidth: '480px',
                height: 'auto',
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSignupLayout;
