import React from 'react';
import { toast } from 'react-toastify';
import { Field, Form } from 'react-final-form';
import { PrimaryButton, TextInput } from 'app/core/design-system';
import { useMutation } from '@tanstack/react-query';
import { addSms } from './ConfirmPhoneNumber';

export function EnterPhoneNumber({ onSuccess, userId }) {
  const addSmsMutation = useMutation({
    mutationFn: addSms,
    onSuccess: () => {
      onSuccess();
    },
  });

  const handleSendSmsConfirmationCode = async function handleSendSmsConfirmationCode({ phoneNumber }) {
    try {
      await addSmsMutation.mutateAsync({
        phoneNumber,
        userId,
      });
      toast('Code sent!');
    } catch (error) {
      toast.error(error.message, { autoClose: 20000 });
    }
  };

  return (
    <div className="flex justify-center items-center w-full">
      <div className="flex flex-col gap-4 mt-4 w-full">
        <Form
          onSubmit={handleSendSmsConfirmationCode}
          render={({ handleSubmit, form, submitting }) => (
            <form onSubmit={handleSubmit} className="gap-2 flex flex-col w-full">
              <Field name="phoneNumber">
                {(props) => (
                  <TextInput
                    placeholder="Enter phone number"
                    value={props.input.value}
                    name={props.input.name}
                    onChange={props.input.onChange}
                    onClear={() => {
                      props.input.onChange('');
                    }}
                  />
                )}
              </Field>
              <div className="flex gap-2 w-full">
                <PrimaryButton onClick={form.submit} disabled={submitting} width="full">
                  Send a confirmation code
                </PrimaryButton>
              </div>
            </form>
          )}
        />
      </div>
    </div>
  );
}
