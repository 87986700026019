import { Autocomplete as NextUIAutocomplete, AutocompleteItem } from '@nextui-org/react';

interface AutocompleteProps {
  label: string;
  selectedItem?: string;
  onChange?: (value: string) => void;
  required?: boolean;
  errorMessage?: string;
  options: AutocompleteItem[];
  disabled?: boolean;
  placeholder?: string;
  emptyPlaceholder?: string;
}

export interface AutocompleteItem {
  value: string;
  label: string;
}
export function Autocomplete(props: AutocompleteProps) {
  const { options, label, selectedItem, onChange, emptyPlaceholder, placeholder, required, disabled, ...rest } = props;

  return (
    <NextUIAutocomplete
      {...rest}
      key={label}
      label={label}
      isInvalid={!!props.errorMessage}
      placeholder={options.length === 0 ? emptyPlaceholder : placeholder}
      onSelectionChange={onChange}
      selectedKey={selectedItem}
      isRequired={required}
      isDisabled={disabled}
      size="sm"
      labelPlacement="inside"
      inputProps={{ classNames: { input: 'text-base' } }}
    >
      {options.map((option) => (
        <AutocompleteItem key={option.value} value={option.value} classNames={{ title: 'text-base' }}>
          {option.label}
        </AutocompleteItem>
      ))}
    </NextUIAutocomplete>
  );
}
