import { useMutation } from '@tanstack/react-query';
import { PrimaryButton } from 'app/core/design-system/atoms/PrimaryButton/PrimaryButton';
import { TextButton } from 'app/core/design-system/atoms/TextButton/TextButton';
import { TextInput } from 'app/core/design-system/atoms/TextInput/TextInput';
import { InformationCircleIcon } from '@heroicons/react/outline';
import axios from 'axios';
import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { toast } from 'react-toastify';
import { z } from 'zod';

const JoinOrgRequestFormSchema = z.object({
  email: z.string().email(),
});

function constructErrors(errors) {
  return errors?.reduce((acc, error) => {
    acc[error.path[0]] = error.message;

    return acc;
  }, {});
}

export async function createVerificationRequestWithEmail(email: string) {
  const response = await axios.post(
    '/api/public/join-organization-requests',
    { requestedAdminEmail: email },
    {
      withCredentials: true,
    },
  );
  return response.data;
}

export function JoinOrgView({
  onSubmit,
  previous,
  next,
}: {
  onSubmit?: () => Promise<void>;
  previous: () => void;
  next: () => void;
}) {
  const [hasErrors, setHasErrors] = useState(false);

  const createRequestMutation = useMutation({
    mutationFn: createVerificationRequestWithEmail,
  });

  const handleFormSubmit = async function handleFormSubmit({ email }: { email: string }) {
    try {
      await createRequestMutation.mutateAsync(email.toLowerCase().trim());
      await onSubmit?.();
      toast("If the user exists, we'll notify them of your request.");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || 'Something went wrong creating the request. Please try again.';
      toast.error(errorMessage, {
        autoClose: 20000,
      });
    }
  };

  const handleFormValidate = function handleFormValidate(values) {
    try {
      JoinOrgRequestFormSchema.parse(values);
      setHasErrors(false);
    } catch (error) {
      setHasErrors(true);
      return constructErrors(error.errors);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <Form
        onSubmit={handleFormSubmit}
        initialValues={{ serialNumber: null }}
        validate={handleFormValidate}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} className="flex flex-col gap-2">
            <div className="text-black flex items-start text-sm">
              <InformationCircleIcon className="min-h-4 h-4 min-w-4 w-4 mr-1 mt-1" />
              If you know the email of your administrator, please enter it below and contact them to process your
              request.
            </div>
            <div className="flex gap-2">
              <Field name="email">
                {(props) => (
                  <TextInput
                    key="email"
                    label="Email"
                    {...props.input}
                    required
                    errorMessage={props.meta.modified && props.meta.error}
                    placeholder="Enter the email of your administrator"
                  />
                )}
              </Field>
              <PrimaryButton
                width="fit"
                onClick={form.submit}
                disabled={hasErrors}
                loading={createRequestMutation?.isPending}
                size="sm"
              >
                Submit
              </PrimaryButton>
            </div>
            <div className="w-full flex justify-between">
              <TextButton onClick={previous} width="fit">
                Back
              </TextButton>
              <TextButton onClick={next} width="fit">
                I don&apos;t know
              </TextButton>
            </div>
          </form>
        )}
      />
    </div>
  );
}
