import { useState } from 'react';
import { Progress } from '@nextui-org/react';
import { OnboardingUserType } from '@prisma/client';
import { SelectUserType } from './Views/SelectUserType';
import { InstallerView } from './Views/InstallerView';
import { CheckCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { Modal } from '../Modal/Modal';
import { useModalDisclosure } from '../Modal/useModalDisclosure';
import { TextButton } from '../../atoms/TextButton/TextButton';
import { RequestAccess, RequestAccessView } from '../RequestAccess/RequestAccess';
import { EnterAppView } from './Views/EnterAppView';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useRouter } from 'next/navigation';
import { toast } from 'react-toastify';

export type OnboardingView = 'start' | 'installer-step-1' | 'device_owner-step-1' | 'device_owner-step-2';

export const updateOnboarding = async ({ userType }) => {
  const response = await axios.post(
    `/api/users/me/onboarding`,
    { userType },
    {
      withCredentials: true,
    },
  );
  return response.data;
};

function getProgressValue(view: OnboardingView) {
  switch (view) {
    case 'installer-step-1':
    case 'device_owner-step-2':
      return 100;
    case 'device_owner-step-1':
      return 75;
    default:
      return 50;
  }
}

function getStepLabel(view: OnboardingView, requestAccessView?: RequestAccessView) {
  switch (view) {
    case 'installer-step-1':
    case 'device_owner-step-2':
      return 'Onboarding complete';
    case 'device_owner-step-1': {
      if (requestAccessView === 'org-choice' || requestAccessView === 'join-org') {
        return 'Request access to an organization';
      } else if (requestAccessView === 'create-org') {
        return 'Request access to a new organization';
      } else {
        return 'Request access to a Frio S1 device';
      }
    }
    case 'start':
    default:
      return 'Email verified. Choose your user type.';
  }
}
export function Onboarding() {
  const [view, setView] = useState<OnboardingView>('start');
  const [requestAccessView, setRequestAccessView] = useState<RequestAccessView>('start');
  const router = useRouter();

  const {
    isOpen: isHelpModalOpen,
    open: openHelpModal,
    onChange: onHelpModalChange,
    close: closeHelpModalModal,
  } = useModalDisclosure();

  const updateOnboardingMutation = useMutation({
    mutationFn: updateOnboarding,
  });

  const handleUpdateOnboarding = async function handleUpdateOnboarding(userType) {
    try {
      await updateOnboardingMutation.mutateAsync({
        userType,
      });
    } catch (error) {
      if (error?.response?.status === 409) {
        toast.error('You have already completed the onboarding flow. If this error persists, please contact support.', {
          autoClose: 20000,
        });
      } else {
        toast.error('Something went wrong. Please try again.', { autoClose: 20000 });
      }
    }
  };

  const handleSubmitRequestAccess = async function handleSubmitRequestAccess() {
    await handleUpdateOnboarding(OnboardingUserType.device_owner);
    setView('device_owner-step-2');
  };

  const handleFinishDeviceOwnerFlow = function handleEnterApp() {
    router.refresh();
  };

  const handleFinishInstallerFlow = async function handleFinishInstallerFlow() {
    await handleUpdateOnboarding(OnboardingUserType.installer);
    router.refresh();
  };

  const VIEW_MAPPING = {
    start: (
      <SelectUserType
        onSelectInstaller={() => setView('installer-step-1')}
        onSelectOwner={() => setView('device_owner-step-1')}
      />
    ),
    'installer-step-1': <InstallerView next={handleFinishInstallerFlow} previous={() => setView('start')} />,
    'device_owner-step-1': (
      <RequestAccess
        onSubmit={handleSubmitRequestAccess}
        previous={() => setView('start')}
        onViewChanged={setRequestAccessView}
      />
    ),
    'device_owner-step-2': <EnterAppView next={handleFinishDeviceOwnerFlow} />,
  };

  return (
    <div className="flex gap-2 flex-col w-full max-w-full lg:w-96 lg:max-w-96">
      <Modal
        isOpen={isHelpModalOpen}
        onChange={onHelpModalChange}
        primaryText="Close"
        primaryAction={closeHelpModalModal}
        title="Onboarding Help"
        size="2xl"
      >
        <div className="flex flex-col gap-2">
          <p className="text-justify">
            You currently have an unverified account, which can be used to connect Frio S1 devices to the internet using
            the Frio mobile app. This account allows you to run Spot Checks from the Frio S1 device. It is meant for
            installers, technicians, and anyone else who may be working with Frio S1 devices on a temporary basis across
            multiple organizations. If you fit this group, select <span className="font-bold">I am an Installer</span>{' '}
            and follow the directions on the home page to get a device connected to run a Spot Check.
          </p>
          <p className="text-justify">
            Verified accounts can gain permanent access to connected Frio S1 devices to monitor status, receive alarm
            notifications, and manage Frio S1 devices. This type of account is meant for device owners, facilities
            engineers, building managers, and anyone else who needs permanent access to a set of devices. If you fit
            this group, select <span className="font-bold">I own or manage Frio S1 devices</span> and enter the serial
            number of your device located on the side label. If you do not know the serial number, you may request to
            join an existing organization by reaching out to your orgnization&apos;s admin or request a new
            organization. You will be notified when your account verification request is accepted.
          </p>
        </div>
      </Modal>
      <div className="flex justify-between items-center text-sm gap-2">
        <div className="flex gap-2 items-center grow">
          {getStepLabel(view, requestAccessView)} <CheckCircleIcon className="w-5 h-5 text-green-500" />
        </div>
        <div className="p-1">
          <TextButton onClick={openHelpModal} width="fit">
            <div className="flex gap-0.5 items-center">
              What is this
              <QuestionMarkCircleIcon className="w-3 h-3" />
            </div>
          </TextButton>
        </div>
      </div>
      <Progress aria-label={getStepLabel(view, requestAccessView)} value={getProgressValue(view)} color="success" />
      {VIEW_MAPPING[view]}
    </div>
  );
}
