import states from 'states-us';
import provinces from 'provinces-ca';
import { CountryConfig } from './types';

const US: CountryConfig = {
  label: 'United States',
  value: 'United States',
  addressConfig: [
    {
      name: 'addressLine1',
      label: 'Street address',
      width: 'full',
      required: false,
    },
    {
      name: 'addressLine2',
      label: 'Apt/Suite/Floor/Other',
      width: 'full',
      required: false,
    },
    {
      name: 'addressLevel2',
      label: 'City',
      width: 'half',
      required: true,
    },
    {
      name: 'addressLevel1',
      label: 'State',
      width: 'half',
      type: 'autocomplete',
      required: true,
      options: states.map((state) => ({ value: state.name, label: state.name })),
    },
    {
      name: 'postalCode',
      label: 'Zip Code',
      width: 'half',
      required: true,
    },
  ],
};

const CA: CountryConfig = {
  label: 'Canada',
  value: 'Canada',
  addressConfig: [
    {
      name: 'addressLine1',
      label: 'Street address',
      width: 'full',
      required: false,
    },
    {
      name: 'addressLine2',
      label: 'Apt/Suite/Floor/Other',
      width: 'full',
      required: false,
    },
    {
      name: 'addressLevel2',
      label: 'City',
      width: 'half',
      required: true,
    },
    {
      name: 'addressLevel1',
      label: 'Province',
      width: 'half',
      type: 'autocomplete',
      required: true,
      options: provinces.map((province) => ({ value: province.name, label: province.name })),
    },
    {
      name: 'postalCode',
      label: 'Postal Code',
      width: 'half',
      required: true,
    },
  ],
};

export const countryConfigs = { US, CA };
