import { Card as NextUICard, CardHeader, Divider, CardBody } from '@nextui-org/react';
import { ReactNode } from 'react';
import { SecondaryButton } from '../SecondaryButton/SecondaryButton';

interface CardProps {
  children: ReactNode;
  headerTitle?: ReactNode;
  headerActionText?: string | ReactNode;
  headerAction?: () => void;
  height?: 'full' | 'fit';
}

export function Card(props: CardProps) {
  const { children, headerAction, headerTitle, headerActionText, height = 'fit' } = props;
  return (
    <NextUICard classNames={{ base: `shrink-0 ${height === 'full' ? 'h-full' : 'h-fit'}` }}>
      {headerTitle && (
        <CardHeader className="flex justify-between min-h-[72px]">
          <h3 className="text-lg">{headerTitle}</h3>
          {headerAction && (
            <SecondaryButton onClick={headerAction} size="sm">
              {headerActionText}
            </SecondaryButton>
          )}
        </CardHeader>
      )}
      <Divider />
      <CardBody>{children}</CardBody>
    </NextUICard>
  );
}
