import {
  AdminCreateUserCommand,
  AdminDeleteUserCommand,
  AdminSetUserPasswordCommand,
  AdminUpdateUserAttributesCommand,
  CognitoIdentityProviderClient,
  ListUsersCommand,
  MessageActionType,
} from '@aws-sdk/client-cognito-identity-provider';
import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { logger } from '../logger';

export class PasswordResetRequiredError extends Error {}

export class CognitoService {
  private cognitoClient: CognitoIdentityProviderClient;
  private userPool: CognitoUserPool;
  constructor(userPoolId: string, clientId: string) {
    logger.info('Initializing Cognito Service');
    this.cognitoClient = new CognitoIdentityProviderClient({ region: 'us-east-1' });
    this.userPool = new CognitoUserPool({
      UserPoolId: userPoolId,
      ClientId: clientId,
    });
  }

  async authenticate(email: string, password: string): Promise<CognitoUserSession> {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    });
    return await new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(
        new AuthenticationDetails({
          Username: email,
          Password: password,
        }),
        {
          onSuccess: (session) => {
            resolve(session);
          },
          onFailure: (err) => {
            if (err?.code === 'PasswordResetRequiredException') {
              reject(new PasswordResetRequiredError());
            }
            reject(err);
          },
        },
      );
    });
  }

  async createUser(email: string) {
    const input = {
      UserPoolId: this.userPool.getUserPoolId(),
      Username: email,
      UserAttributes: [
        {
          Name: 'email',
          Value: email,
        },
      ],
      ForceAliasCreation: true,
      MessageAction: MessageActionType.SUPPRESS,
    };

    const command = new AdminCreateUserCommand(input);
    await this.cognitoClient.send(command);
    logger.info(`Created user ${email} in Cognito`);
  }

  async refreshSession(email: string, refreshTokenString: string): Promise<CognitoUserSession> {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    });
    const refreshToken = new CognitoRefreshToken({ RefreshToken: refreshTokenString });
    return await new Promise((resolve, reject) => {
      cognitoUser.refreshSession(refreshToken, (err, session) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });
  }

  async findUser(email: string) {
    const listUsersCommand = new ListUsersCommand({
      UserPoolId: this.userPool.getUserPoolId(),
      Filter: `email = "${email}"`,
    });

    const userResponse = await this.cognitoClient.send(listUsersCommand);
    return userResponse.Users?.[0];
  }

  async createUserAndSetPassword(email: string, password: string) {
    await this.createUser(email);
    await this.setPassword(email, password);
  }

  async signUpUnverifiedUser(email: string, password: string) {
    this.userPool.signUp(email, password, [], null, (err) => {
      if (err) {
        logger.error(err);
        throw err;
      }
      logger.info(`Created unverified user ${email} in Cognito`);
    });
  }

  async confirmUser(email: string, code: string) {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool,
    });

    return await new Promise<void>((resolve, reject) => {
      cognitoUser.confirmRegistration(code, true, (err) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  }

  private async setPassword(email: string, password: string) {
    const passwordCommand = new AdminSetUserPasswordCommand({
      UserPoolId: this.userPool.getUserPoolId(),
      Username: email,
      Password: password,
      Permanent: true,
    });
    await this.cognitoClient.send(passwordCommand);
    logger.info(`Set password for user ${email} in Cognito`);
  }

  async setEmailVerified(email: string) {
    const command = new AdminUpdateUserAttributesCommand({
      UserPoolId: this.userPool.getUserPoolId(),
      Username: email,
      UserAttributes: [
        {
          Name: 'email_verified',
          Value: 'true',
        },
      ],
    });

    await this.cognitoClient.send(command);
  }

  async createUserIfNeededAndSetPassword(email: string, password: string) {
    const user = await this.findUser(email);

    if (!user) {
      await this.createUserAndSetPassword(email, password);
    } else {
      await this.setPassword(email, password);
    }
  }

  async deleteUser(email: string) {
    const command = new ListUsersCommand({
      UserPoolId: this.userPool.getUserPoolId(),
      Filter: `email = "${email}"`,
    });

    const userResponse = await this.cognitoClient.send(command);

    if (userResponse.Users.length > 0) {
      const username = userResponse.Users[0].Username;
      const deleteCommand = new AdminDeleteUserCommand({
        UserPoolId: this.userPool.getUserPoolId(),
        Username: username,
      });
      await this.cognitoClient.send(deleteCommand);
      logger.info(`Deleted user ${email} from Cognito`);
    }
  }
}
