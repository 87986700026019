import pino from 'pino';
import omit from 'lodash/omit';
import { NextApiRequest, NextApiResponse } from 'next';

export function buildBrowserLog(o) {
  const { msg, level, ...rest } = o;
  /**
   * The browser logger does not respect or play nice with the formatters below, so we have to do manual transformations for the browser
   */
  return {
    message: msg,
    timestamp: new Date(Date.now()).toISOString(),
    level: translateLogLevel(level),
    ...omit(rest, ['time', 'type']),
  };
}

export function translateLogLevel(level: number) {
  switch (level) {
    case 50:
      return 'error';
    case 40:
      return 'warn';
    case 30:
      return 'info';
    case 25:
      return 'http';
    case 20:
    default:
      return 'debug';
  }
}

// Adapted from https://betterstack.com/community/guides/logging/how-to-install-setup-and-use-pino-to-log-node-js-applications/
const pinoConfig = {
  browser: {
    asObject: true,
    serialize: true,
    write: {
      error: function (o) {
        const { time, msg, type, level, ...rest } = o;
        console.error(buildBrowserLog({ time, msg, type, level, error: rest }));
      },
      warn: function (o) {
        console.warn(buildBrowserLog(o));
      },
      info: function (o) {
        console.info(buildBrowserLog(o));
      },
      http: function (o) {
        console.info(buildBrowserLog(o));
      },
      debug: function (o) {
        console.debug(buildBrowserLog(o));
      },
    },
  },
  level: 'debug',
  customLevels: {
    http: 25,
  },
  messageKey: 'message',
  errorKey: 'error',
  timestamp: () => `,"timestamp":"${new Date(Date.now()).toISOString()}"`,
  formatters: {
    level: (label) => {
      return { level: label };
    },
    bindings: () => {
      return {};
    },
  },
  enabled: process.env.DISABLE_LOGGING !== 'true',
};

export const logger = pino(pinoConfig);

const REDACTED_PROPERTIES = ['password'];
export const withApiRequestLogging = (handler) => {
  if (process.env.DISABLE_LOGGING === 'true') {
    return handler;
  }
  return async (req: NextApiRequest, res: NextApiResponse) => {
    const url = req?.url;
    const method = req?.method;
    const userAgent = req?.headers?.['user-agent'] || 'NO_USERAGENT';
    const bodyJSON = JSON.stringify(req?.body?.filter?.((key) => !REDACTED_PROPERTIES.includes(key)));
    const contentLength = req?.headers?.['content-length'];
    logger.http(
      {
        method,
        userAgent,
        contentLength,
        body: req?.body && bodyJSON,
        url,
      },
      `Request to ${url}`,
    );
    return handler(req, res);
  };
};

// Next 13 does not expose the response body in the response object, so we have to log it ourselves
// https://github.com/vercel/next.js/discussions/30681
export const logApiResponse = ({
  req,
  res,
  body,
  error,
}: {
  req: NextApiRequest;
  res: NextApiResponse;
  body?: any;
  error?: any;
}) => {
  if (process.env.DISABLE_LOGGING === 'true') {
    return;
  }
  const url = req?.url;
  const method = req?.method;
  const statusCode = res?.statusCode;
  const responseBody = typeof body !== 'string' ? JSON.stringify(body) : body;
  const contentLength = res?.getHeader && res?.getHeader('content-length');

  if (statusCode >= 500) {
    logger.error(error, responseBody);
  } else {
    logger.http(
      {
        method,
        url,
        statusCode,
        contentLength,
      },
      `Response from API call to ${url}`,
    );
  }
};
