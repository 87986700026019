import { useEffect, useState } from 'react';
import { StartView } from './Views/StartView';
import { SerialNumberView } from './Views/SerialNumberView';
import { OrgChoiceView } from './Views/OrgChoiceView';
import { JoinOrgView } from './Views/JoinOrgView';
import { CreateOrgView } from './Views/CreateOrgView';

export type RequestAccessView = 'start' | 'serial-number' | 'org-choice' | 'join-org' | 'create-org' | 'enter-app';

interface RequestAccessProps {
  onSubmit?: () => Promise<void>;
  previous?: () => void;
  onViewChanged?: (view: RequestAccessView) => void;
}

export function RequestAccess({ onSubmit, previous, onViewChanged }: RequestAccessProps) {
  const [view, setView] = useState<RequestAccessView>('start');

  useEffect(() => {
    onViewChanged?.(view);
  }, [view]);

  const VIEW_MAPPING = {
    start: (
      <StartView
        onSelectOrgChoice={() => setView('org-choice')}
        onSelectSerialNumber={() => setView('serial-number')}
        previous={previous}
      />
    ),
    'serial-number': <SerialNumberView onSubmit={onSubmit} previous={() => setView('start')} />,
    'org-choice': (
      <OrgChoiceView
        onJoinExistingOrg={() => setView('join-org')}
        onCreateOrg={() => setView('create-org')}
        previous={() => setView('start')}
      />
    ),
    'join-org': (
      <JoinOrgView onSubmit={onSubmit} previous={() => setView('org-choice')} next={() => setView('create-org')} />
    ),
    'create-org': <CreateOrgView onSubmit={onSubmit} previous={() => setView('org-choice')} />,
  };

  return VIEW_MAPPING[view];
}
