import { Navbar as NextUINavbar, NavbarContent, NavbarMenuToggle, NavbarMenu } from '@nextui-org/react';
import { useState } from 'react';
import { Breadcrumbs } from '../../Breadcrumbs/Breadcrumbs';
import { TitleBreadcrumbsType } from 'types';
import { NavItem, NavItemGroup, renderNavGroup, renderNavItem } from '../common';
import { HomeIcon } from '@heroicons/react/outline';

export interface NavbarProps {
  navItems: Array<NavItem | NavItemGroup>;
  breadcrumbPath: TitleBreadcrumbsType[];
}

export function Navbar(props) {
  const { navItems, breadcrumbPath = [] } = props;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const items = [
    {
      url: '/',
      label: 'Home',
      icon: <HomeIcon className="w-3.5 h-3.5 sm:w-6 sm:h-6 lg:h-7 lg:w-7" data-testid="home-link" />,
    },
    ...breadcrumbPath,
  ];

  return (
    <NextUINavbar
      onMenuOpenChange={setIsMenuOpen}
      classNames={{
        base: 'z-20 justify-between flex bg-blue-500',
        wrapper: 'px-2 md:px-4',
        menu: 'p-4 text-white navbar-menu',
        toggle: 'text-white',
      }}
    >
      <NavbarContent>
        <Breadcrumbs items={items} />
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarMenuToggle
          aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
          className="lg:hidden"
          data-cy="hamburger-menu"
        />
      </NavbarContent>
      <NavbarMenu data-testid="navbar-menu" data-cy="navbar-menu">
        {navItems.map((item) => {
          if (item.items) {
            return renderNavGroup(item);
          }

          return renderNavItem(item);
        })}
      </NavbarMenu>
    </NextUINavbar>
  );
}
