import { PaginationState, RowSelectionState, SortingState } from '@tanstack/react-table';
import { createContext, useContext, useState } from 'react';
import { get } from 'lodash';

interface TableInfo {
  rowSelection?: RowSelectionState;
  sortingState?: SortingState;
  paginationState?: PaginationState;
}
type TableContext = {
  setRowSelection: (tableId: string) => (items: Record<string, boolean>) => void;
  setSortingState: (tableId: string) => (sortingState: SortingState) => void;
  setPaginationState: (tableId: string) => (paginationState: PaginationState) => void;
  tableInfo: Record<string, TableInfo>;
};
export const TableContext = createContext<TableContext>(undefined);

export const recursiveSort = (rowA, rowB, itemsToSortBy: string[] = [], direction = 'asc') => {
  if (itemsToSortBy.length <= 0) {
    return 0;
  }
  const itemToSortBy = itemsToSortBy.shift();
  // Items could have a truthy value, be NULL, or undefined
  const rowAItem = (get(rowA, itemToSortBy, '') || '').toString();
  const rowBItem = (get(rowB, itemToSortBy, '') || '').toString();
  let compareResult;
  if (direction === 'asc') {
    compareResult = rowAItem?.localeCompare(rowBItem, undefined, { sensitivity: 'base' });
  } else {
    compareResult = rowBItem?.localeCompare(rowAItem, undefined, { sensitivity: 'base' });
  }

  if (compareResult > 0) {
    return 1;
  } else if (compareResult < 0) {
    return -1;
  } else {
    return recursiveSort(rowA, rowB, itemsToSortBy, direction);
  }
};

export function TableContextWrapper({ children }) {
  const [tableInfo, setTableInfo] = useState({
    inventory: {
      sortingState: [{ id: 'manufacturingStatus.updatedAt', desc: true }],
    },
    devices: {
      sortingState: [],
    },
    'devices-on-group-page': {
      sortingState: [],
    },
    deviceGroups: {
      sortingState: [],
    },
    joinOrgRequest: {
      sortingState: [{ id: 'createdAt', desc: true }],
    },
    createOrgRequest: {
      sortingState: [{ id: 'createdAt', desc: true }],
    },
    invalidOrgRequest: {
      sortingState: [{ id: 'createdAt', desc: true }],
    },
    alarmEventsLog: {
      sortingState: [{ id: 'time', desc: true }],
    },
    alarms: {
      sortingState: [{ id: 'timeTrip', desc: true }],
    },
    heaterLog: {
      sortingState: [{ id: 'time', desc: true }],
    },
    networkConnectionLog: {
      sortingState: [{ id: 'time', desc: true }],
    },
    spotCheck: {
      sortingState: [{ id: 'createdAt', desc: true }],
    },
    users: {
      sortingState: [{ id: 'preferences.email', desc: false }],
    },
    organizations: {
      sortingState: [{ id: 'name', desc: false }],
    },
    pendingOrgRequests: {
      sortingState: [{ id: 'createdAt', desc: true }],
    },
  });

  const setRowSelection = (tableId) => (items) => {
    const prevTableInfo = tableInfo[tableId] || {};
    prevTableInfo.rowSelection = items;
    setTableInfo((prevState) => ({ ...prevState, [tableId]: prevTableInfo }));
  };

  const setSortingState = (tableId: string) => (sortingState: SortingState) => {
    const prevTableInfo = tableInfo[tableId] || {};
    prevTableInfo.sortingState = sortingState;
    setTableInfo((prevState) => ({ ...prevState, [tableId]: prevTableInfo }));
  };

  const setPaginationState = (tableId: string) => (paginationState: PaginationState) => {
    const prevTableInfo = tableInfo[tableId] || {};
    prevTableInfo.paginationState = paginationState;
    setTableInfo((prevState) => ({ ...prevState, [tableId]: prevTableInfo }));
  };

  const sharedState = {
    setRowSelection,
    setSortingState,
    setPaginationState,
    tableInfo,
  } as TableContext;

  return <TableContext.Provider value={sharedState}>{children}</TableContext.Provider>;
}

export function useTableContext(tableName: string) {
  const { tableInfo, setPaginationState, setRowSelection, setSortingState } = useContext<TableContext>(TableContext);
  return {
    tableInfo: tableInfo[tableName],
    setPaginationState: setPaginationState(tableName),
    setRowSelection: setRowSelection(tableName),
    setSortingState: setSortingState(tableName),
  };
}
