import { cloneElement } from 'react';
import type { FC, ReactElement, ReactNode } from 'react';
import { TOOLTIP_ID } from './TooltipWrapper';

// Taken from https://react-tooltip.com/docs/examples/place
type Places =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end';

export interface TooltipProps {
  children: ReactNode | JSX.Element;
  place?: Places;
  label: string;
}

export const Tooltip: FC<TooltipProps> = (props) => {
  const { children, place = 'top', label } = props;

  return cloneElement(children as ReactElement, {
    'data-tooltip-id': TOOLTIP_ID,
    'data-tooltip-content': label,
    'data-tooltip-place': place,
  });
};
