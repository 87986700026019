import { Breadcrumbs as NextUIBreadcrumbs, BreadcrumbItem } from '@nextui-org/breadcrumbs';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react';
import classNames from 'classnames';
import { ReactNode } from 'react';

interface BreadcrumbLinkProps {
  url: string;
  label?: string;
  icon?: ReactNode;
}
export interface BreadcrumbsProps {
  items?: BreadcrumbLinkProps[];
  color?: 'white' | 'black';
}

export const COLOR_MAPPING = {
  white: 'text-white',
  black: 'text-black',
};

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { items, color = 'white' } = props;

  return (
    <NextUIBreadcrumbs
      data-cy="breadcrumbs"
      maxItems={4}
      itemsAfterCollapse={3}
      itemsBeforeCollapse={1}
      classNames={{
        list: 'flex-nowrap truncate',
        base: 'flex items-center',
        separator: classNames('text-base sm:text-2xl lg:text-3xl px-2', COLOR_MAPPING[color]),
      }}
      separator="/"
      renderEllipsis={({ items, ellipsisIcon, separator }) => (
        <div className="flex items-center">
          <Dropdown>
            <DropdownTrigger>
              <Button isIconOnly className="min-w-6 w-6 h-6" size="sm" variant="flat">
                {ellipsisIcon}
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Routes">
              {items.map((item, index) => (
                <DropdownItem key={index} href={item.href}>
                  <span className="text-base sm:text-2xl lg:text-3xl">{item.children}</span>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          {separator}
        </div>
      )}
    >
      {items.map((item) => (
        <BreadcrumbItem
          href={item.url}
          key={item.label}
          classNames={{
            item: classNames('text-base sm:text-2xl lg:text-3xl truncate', COLOR_MAPPING[color]),
            separator: classNames('text-base sm:text-2xl lg:text-3xl px-2', COLOR_MAPPING[color]),
          }}
        >
          {item.icon || item.label}
        </BreadcrumbItem>
      ))}
    </NextUIBreadcrumbs>
  );
};
