import { Button } from '@nextui-org/react';

export const CustomButton = (props) => {
  const { children, ...otherProps } = props;

  return (
    <Button
      {...otherProps}
      className="text-wrap whitespace-normal text-left h-24 text-lg bg-gray-50 hover:bg-white items-center justify-between cursor-pointer rounded-lg gap-4 p-4 border-2"
    >
      {children}
    </Button>
  );
};
