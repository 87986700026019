import type { FC, ReactNode } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

export interface TooltipWrapperProps {
  children: ReactNode | JSX.Element;
}

export const TOOLTIP_ID = 'basic-tooltip';

export const TooltipWrapper: FC<TooltipWrapperProps> = (props) => {
  const { children } = props;
  return (
    <>
      {children}
      <ReactTooltip id={TOOLTIP_ID} />
    </>
  );
};
