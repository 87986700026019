import { CustomButton } from '../CustomButton';

interface SelectUserTypeProps {
  onSelectInstaller: () => void;
  onSelectOwner: () => void;
}
export function SelectUserType({ onSelectInstaller, onSelectOwner }: SelectUserTypeProps) {
  return (
    <div className="flex flex-col gap-2">
      <CustomButton onClick={onSelectInstaller}>I am an Installer</CustomButton>
      <CustomButton onClick={onSelectOwner}>I own or manage Frio S1 devices</CustomButton>
    </div>
  );
}
