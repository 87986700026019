import { Textarea } from '@nextui-org/input';
import type { FC } from 'react';

export interface TextAreaProps {
  /**
   * Change handler called when the user enters input
   * @param value The user's input
   */
  onChange?: (value: string) => void;
  /**
   * Clear handled called when the user presses the clear button to delete the input
   */
  onClear?: () => void;
  /**
   * The value of the input, if controlled
   */
  value?: string;
  /**
   * Placeholder text
   */
  placeholder?: string;
  /**
   * The icon to render if the input is empty
   */
  leftIcon?: JSX.Element;
  /**
   * The name attribute
   */
  name?: string;
  /**
   * The label for the input
   */
  label?: string;
  /**
   * The autocomplete attribute according to the HTML standard
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  autocomplete?: string;
  /**
   * If the input is required in a form
   */
  required?: boolean;
  errorMessage?: string;
  /**
   * If the first character of each sentence should be capitalized automatically.
   */
  autocapitalize?: 'off' | 'on';
}

export const TextArea: FC<TextAreaProps> = (props) => {
  const { onClear, onChange, value, label, required, leftIcon, errorMessage, autocapitalize = 'off', ...rest } = props;
  const handleChange = function handleChange(newValue: string) {
    onChange?.(newValue);
  };

  const handleClear = function handleClear() {
    onClear?.();
  };
  return (
    <div className="relative w-full">
      <Textarea
        type="text"
        labelPlacement="inside"
        classNames={{ inputWrapper: 'border-2', input: 'text-base' }}
        label={label}
        onValueChange={handleChange}
        onClear={handleClear}
        value={value}
        isRequired={required}
        startContent={leftIcon}
        errorMessage={errorMessage}
        isInvalid={!!errorMessage}
        autoCapitalize={autocapitalize}
        size="md"
        {...rest}
      />
    </div>
  );
};
