import React from 'react';
import UserSignupLayout from 'app/core/layouts/UserSignupLayout';
import { Login } from 'app/auth/pages/Login';

const LoginPage = () => {
  return (
    <UserSignupLayout title="Log In">
      <Login />
    </UserSignupLayout>
  );
};

export default LoginPage;
