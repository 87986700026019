import axios from 'axios';

export async function updateDevice({ deviceId, properties }) {
  const response = await axios.patch(`/api/devices/${deviceId}`, properties, {
    withCredentials: true,
  });

  return response.data;
}

export async function cancelOverride({ deviceId }) {
  const response = await axios.delete(`/api/devices/${deviceId}/override`, {
    withCredentials: true,
  });

  return response.data;
}

export async function updateOverride({ deviceId, properties }) {
  const response = await axios.patch(`/api/devices/${deviceId}/override`, properties, {
    withCredentials: true,
  });

  return response.data;
}

export async function getOverride({ queryKey }) {
  const [, deviceId] = queryKey;
  const response = await axios.get(`/api/devices/${deviceId}/override`, {
    withCredentials: true,
  });

  return response.data;
}

export async function triggerGfepTest({ deviceId }) {
  const response = await axios.post(`/api/devices/${deviceId}/gfepTest`, undefined, {
    withCredentials: true,
  });

  return response.data;
}

export async function updateDeviceHwSettings({ deviceId, settings }) {
  const response = await axios.patch(`/api/devices/${deviceId}/hwSettings`, settings, {
    withCredentials: true,
  });

  return response.data;
}

export async function startSpotCheck({ deviceId }) {
  const response = await axios.post(`/api/devices/${deviceId}/spotCheck`, undefined, {
    withCredentials: true,
  });

  return response.data;
}

export async function cancelSpotCheck({ runId }) {
  const response = await axios.post(`/api/spot-checks/${runId}/cancel`, {
    withCredentials: true,
  });

  return response.data;
}

export async function getSpotCheck({ runId }) {
  const response = await axios.get(`/api/spot-checks/${runId}`, {
    withCredentials: true,
  });

  return response.data;
}

export async function deleteUser({ userId }) {
  const response = await axios.delete(`/api/users/${userId}`, {
    withCredentials: true,
  });

  return response.data;
}

export async function getOrganizations({ queryKey }) {
  const [, includeCounts] = queryKey;
  const response = await axios.get(`/api/organizations`, {
    withCredentials: true,
    params: { includeCounts },
  });
  return response?.data?.organizations;
}

export async function moveDevice({ deviceId, organizationId }) {
  await axios.post(
    `/api/devices/${deviceId}/move`,
    { organizationId },
    {
      withCredentials: true,
    },
  );
}

export async function moveDeviceGroup({ deviceGroupId, organizationId }) {
  await axios.post(
    `/api/device-groups/${deviceGroupId}/move`,
    { organizationId },
    {
      withCredentials: true,
    },
  );
}

export async function resetPassword({ userId, email }: { userId?: number; email?: string }) {
  return axios.post(
    `/api/users/reset-password`,
    {
      userId,
      email,
    },
    {
      withCredentials: true,
    },
  );
}

export async function setPassword({ token, password }) {
  return axios.post(
    `/api/users/set-password`,
    {
      token,
      password,
    },
    {
      withCredentials: true,
    },
  );
}

// This is a workaround used to avoid passing null to useSuspenseQuery when we want to conditionally fetch data
// It is a known thing that want to do with useSuspenseQuery, but it is not supported by the library
// https://github.com/TanStack/query/discussions/6206
export function emptyArrayFunction() {
  return Promise.resolve([]);
}

export function emptyObjectFunction() {
  return Promise.resolve({});
}
