import UserSignupLayout from '../layouts/UserSignupLayout';
import { useAuth } from '../context/AuthContext';
import LoginPage from 'pages/login';
import { default as NextLink } from 'next/link';
import { useErrorBoundary } from 'react-error-boundary';
import { Toast } from '../common/Toast';
import { Header, Link, PrimaryButton } from '../design-system';
import { UnauthorizedError } from '../errors/UnauthorizedError';

export function ErrorView() {
  const { resetBoundary } = useErrorBoundary();

  return (
    <div role="alert" className="flex justify-center items-center min-h-dvh">
      <div className="flex flex-col gap-2 items-center">
        <Header>Something went wrong!</Header>
        <PrimaryButton onClick={resetBoundary}>Try again</PrimaryButton>
        <Link href="/" as={NextLink}>
          Go Home
        </Link>
      </div>
    </div>
  );
}

export function ErrorFallback({ error }) {
  const { user, logout } = useAuth();
  if (error instanceof UnauthorizedError) {
    if (user) {
      logout();
    }
    return (
      <UserSignupLayout title="Log In">
        <Toast />
        <LoginPage />
      </UserSignupLayout>
    );
  }

  return <ErrorView />;
}
