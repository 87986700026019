import type { ColumnFilter, FilterFn, PaginationState, RowSelectionState, SortingState } from '@tanstack/react-table';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  Checkbox,
  Pagination,
  Table as NextUITable,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';
import { rankItem } from '@tanstack/match-sorter-utils';
import { Fragment, type ReactNode, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { SortIcon } from './SortIcon';
import { SingleSelect } from '../../atoms/SingleSelect/SingleSelect';

export type CellAlignment = 'left' | 'center' | 'right';

const CellAlignmentMapping = {
  left: 'justify-start',
  center: 'justify-center',
  right: 'justify-end',
};

export interface Column {
  key: string;
  accessorFn?: (row) => string;
  header?: ReactNode;
  customCellRenderer?: (props) => ReactNode;
  cellAlignment?: CellAlignment;
  hidden?: boolean;
  sortingFn?: (itemA, itemB) => number;
  filterFn?: (row, columnId, filterValue) => boolean;
  hideOnMobile?: boolean;
  hideOnDesktop?: boolean;
  mobileHeader?: ReactNode;
  disableSort?: boolean;
}

export interface TableProps<T> {
  data: T[];
  uniqueIdKey?: string;
  columns: Column[];
  globalFilter?: string;
  onRowClick?: (props) => void;
  columnFilters?: ColumnFilter[];
  enableRowSelection?: boolean;
  disablePagination?: boolean;
  defaultRowSelection?: RowSelectionState;
  onRowSelectionChange?: (RowSelection) => void;
  defaultSortingState?: SortingState;
  onSortingChange?: (SortingState) => void;
  defaultPaginationState?: PaginationState;
  onPaginationChange?: (PaginationState) => void;
  ariaLabel: string;
}

type PageSize = 10 | 20 | 50 | 100;
const PAGE_SIZES: PageSize[] = [10, 20, 50, 100];

export function Table<T>(props: TableProps<T>) {
  const {
    data,
    columns,
    globalFilter,
    onRowClick,
    columnFilters,
    enableRowSelection = false,
    disablePagination = false,
    defaultRowSelection = {},
    defaultSortingState = [],
    defaultPaginationState = { pageSize: 20, pageIndex: 0 },
    onRowSelectionChange,
    onSortingChange,
    onPaginationChange,
    uniqueIdKey = 'id',
    ariaLabel,
  } = props;
  const [columnVisibility, setColumnVisibility] = useState(() => {
    return columns.reduce((acc, column) => {
      if (column.hidden) {
        return { ...acc, [column.key]: false };
      }
      return { ...acc, [column.key]: true };
    }, {});
  });

  // https://github.com/TanStack/table/issues/4240
  // https://github.com/TanStack/table/issues/4566
  const memoizedData = useMemo(() => {
    return data ?? [];
  }, [data]);

  // We need to store the state locally instead of relying on context because what happens is anytime react-table makes an update,
  // it will re-render the component and update the context, which would then update the table, which updates the context, creating an infinite loop.
  const [localRowSelection, setLocalRowSelection] = useState<RowSelectionState>(defaultRowSelection);
  const [localSortingState, setLocalSortingState] = useState<SortingState>(defaultSortingState);
  const [localPaginationState, setLocalPaginationState] = useState<PaginationState>(defaultPaginationState);

  useEffect(() => {
    onRowSelectionChange?.(localRowSelection);
  }, [localRowSelection]);

  useEffect(() => {
    onSortingChange?.(localSortingState);
  }, [localSortingState]);

  useEffect(() => {
    onPaginationChange?.(localPaginationState);
  }, [localPaginationState]);

  const columnHelper = createColumnHelper<T>();

  const tableColumns = useMemo(
    () => [
      ...columns.map((column) => {
        const {
          key,
          accessorFn,
          header,
          mobileHeader,
          customCellRenderer,
          cellAlignment,
          sortingFn,
          filterFn,
          hideOnMobile,
          hideOnDesktop,
          disableSort,
        } = column;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // This is how the react-table docs say to create columns, unsure why Typescript does not like it.
        return columnHelper.accessor(accessorFn || key, {
          meta: {
            hideOnMobile,
            hideOnDesktop,
            cellAlignment,
            disableSort,
          },
          id: key,
          header: () => (
            <Fragment>
              <div className="sm:hidden">{mobileHeader}</div>
              <div className={classNames({ 'hidden sm:block': mobileHeader })}>{header}</div>
            </Fragment>
          ),
          cell: (info) => {
            return (
              <div className={classNames('flex', CellAlignmentMapping[cellAlignment])}>
                {(customCellRenderer ? customCellRenderer(info.row.original) : info.getValue()) as ReactNode}
              </div>
            );
          },
          ...(sortingFn && { sortingFn: (itemA, itemB) => sortingFn?.(itemA.original, itemB.original) }),
          ...(filterFn && {
            filterFn: (row, columnId, filterValue) => filterFn?.(row.original, columnId, filterValue),
          }),
        });
      }),
    ],
    [columns],
  );

  const selectionColumn = {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        isSelected={table.getIsAllRowsSelected()}
        isIndeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        isSelected={row.getIsSelected()}
        isDisabled={!row.getCanSelect()}
        isIndeterminate={row.getIsSomeSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  };
  if (enableRowSelection) {
    tableColumns.unshift(selectionColumn);
  }

  // Adapted from https://tanstack.com/table/v8/docs/examples/react/filters
  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);

    addMeta({
      itemRank,
    });

    return itemRank.passed;
  };

  const table = useReactTable({
    getRowId: (row) => row?.[uniqueIdKey],
    state: {
      globalFilter,
      columnVisibility,
      // If this is undefined or an empty array by default it breaks, so we have to conditionally add it.
      // I think it's a bug with the library.
      ...(columnFilters && { columnFilters }),
      rowSelection: localRowSelection,
      sorting: localSortingState,
      pagination: localPaginationState || { pageSize: 20, pageIndex: 0 },
    },
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    data: memoizedData,
    columns: tableColumns,
    globalFilterFn: 'includesString',
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    enableSortingRemoval: true,
    enableFilters: true,
    enableHiding: true,
    enableColumnFilters: true,
    enableGlobalFilter: true,
    sortDescFirst: false,
    // Allows the global filter to search all columns. Without this it would not filter columns with null values in first row
    getColumnCanGlobalFilter: () => true,
    enableRowSelection,
    onRowSelectionChange: setLocalRowSelection,
    onSortingChange: setLocalSortingState,
    onPaginationChange: setLocalPaginationState,
  });

  const headerGroup = table.getHeaderGroups()[0];
  return (
    <div className="flex flex-col w-full">
      <NextUITable
        classNames={{
          wrapper: 'p-0 shadow-none overflow-y-visible overflow-x-scroll',
        }}
        aria-label={ariaLabel}
      >
        <TableHeader data-testid="table-head">
          {headerGroup.headers.map((header) => (
            <TableColumn
              key={header.id}
              className={classNames('group', {
                'hidden sm:table-cell': header?.column?.columnDef?.meta?.hideOnMobile,
                'md:hidden': header?.column?.columnDef?.meta?.hideOnDesktop,
              })}
            >
              {header.id === 'select' || header?.column?.columnDef?.meta?.disableSort ? (
                flexRender(header.column.columnDef.header, header.getContext())
              ) : (
                <button
                  className={classNames(
                    'w-full flex items-center gap-1',
                    CellAlignmentMapping[header?.column?.columnDef?.meta?.cellAlignment || 'left'],
                  )}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {flexRender(header.column.columnDef.header, header.getContext())}
                  {header.column.getCanSort() && <SortIcon isSorted={header.column.getIsSorted()} />}
                </button>
              )}
            </TableColumn>
          ))}
        </TableHeader>
        <TableBody data-testid="table-body" emptyContent={'No results found.'}>
          {table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              onClick={() => onRowClick?.(row.original)}
              className={classNames('shadow', {
                'cursor-pointer': onRowClick,
              })}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  className={classNames({
                    'group-hover:bg-gray-100': onRowClick,
                    'hidden sm:table-cell': cell?.column?.columnDef?.meta?.hideOnMobile,
                    'md:hidden': cell?.column?.columnDef?.meta?.hideOnDesktop,
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </NextUITable>
      {!disablePagination && (
        <div className="flex justify-center mt-4">
          <div className="flex flex-col md:flex-row items-center justify-center gap-2 w-full">
            <div className="w-40">
              <SingleSelect
                label="Rows"
                selectedItem={table.getState().pagination.pageSize.toString()}
                onChange={(newPageSize) => table.setPageSize(newPageSize)}
                options={PAGE_SIZES.map((pageSize) => ({
                  value: pageSize.toString(),
                  label: pageSize.toString(),
                }))}
              />
            </div>

            <Pagination
              showControls
              total={table.getPageCount()}
              initialPage={1}
              onChange={(newPage) => table.setPageIndex(newPage - 1)}
              page={table.getState().pagination.pageIndex + 1}
            />
          </div>
        </div>
      )}
    </div>
  );
}
