import { ReactNode } from 'react';
import { BaseTypography } from './BaseTypography';

type TextVariant = 'default' | 'secondary';
interface TextProps {
  variant?: TextVariant;
  children: ReactNode;
}

const VARIANT_SIZE_MAPPING: Record<TextVariant, string> = {
  default: 'text-base sm:text-lg',
  secondary: 'text-sm sm:text-base',
};
export function Text(props: TextProps) {
  const { variant = 'default' } = props;
  return <BaseTypography as="p" className={VARIANT_SIZE_MAPPING[variant]} {...props} />;
}
