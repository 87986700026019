import { ReactNode } from 'react';
import { BaseTypography, HeaderLevel } from './BaseTypography';

type Level = '1' | '2' | '3' | '4' | '5' | '6';
interface HeaderProps {
  level?: Level;
  children: ReactNode;
}

const LEVEL_MAPPING: Record<Level, HeaderLevel> = {
  '1': 'h1',
  '2': 'h2',
  '3': 'h3',
  '4': 'h4',
  '5': 'h5',
  '6': 'h6',
};

const LEVEL_FONT_SIZE_MAPPING = {
  '1': 'text-4xl sm:text-5xl',
  '2': 'text-3xl sm:text-4xl',
  '3': 'text-2xl sm:text-3xl',
  '4': 'text-xl sm:text-2xl',
  '5': 'text-lg sm:text-xl',
  '6': 'text-base sm:text-lg',
};

export function Header({ level = '3', ...props }: HeaderProps) {
  return <BaseTypography as={LEVEL_MAPPING[level]} className={LEVEL_FONT_SIZE_MAPPING[level]} {...props} />;
}
