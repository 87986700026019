import { Role } from '../../../types';
import { OrganizationType } from '@prisma/client';
import { useAuth } from '../context/AuthContext';

export enum PermissionName {
  'view.devicegroups',
  'view.devicegroups.new',
  'view.devicegroups.deviceGroupId',
  'view.devicegroups.deviceGroupId.settings',
  'view.devices',
  'view.devices.deviceId',
  'view.devices.deviceId.settings',
  'view.organizations',
  'view.organizations.add',
  'view.organizations.sameOrg',
  'view.organizations.otherOrg',
  'view.organizations.pending',
  'view.userpreferences.account',
  'view.userpreferences.notifications',
  'view.support.documentation',
  'view.users.userId',
  'get.users',
  'view.users',
  'view.home',
  'view.verificationRequests',
  'view.inventory',
  'view.inventory.assign',
  'view.inventory.sold',
  'view.weatherData',
  'edit.devices.info',
  'edit.devices.settings',
  'device.blinkup',
  'device.ping',
  'device.override',
  'edit.organization',
  'delete.organization',
  'edit.organization.orgName',
  'edit.organization.inviteSameOrg',
  'edit.organization.inviteOtherOrg',
  'edit.organization.create',
  'view.organization.pending_requests',
  'edit.organization.pending_requests',
  'edit.organization.orgType',
  'edit.organization.orgSalesRep',
  'edit.organization.orgShortName',
  'edit.organization.deleteUserSameOrg',
  'edit.organization.deleteUserOtherOrg',
  'edit.organization.resendInvite',
  'edit.user.preferences',
  'edit.user.role',
  'edit.user.delete',
  'edit.user.phoneNumber',
}

type Organization = OrganizationType | 'none';
type Permissions = {
  [key in PermissionName]: {
    [key in Organization]?: Array<Role>;
  };
};
const PERMISSIONS: Permissions = {
  [PermissionName['view.devicegroups']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.devicegroups.new']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.devicegroups.deviceGroupId']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.devicegroups.deviceGroupId.settings']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.devices']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.devices.deviceId']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.devices.deviceId.settings']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.organizations']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.organizations.add']]: {
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.organizations.sameOrg']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.organizations.otherOrg']]: {
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.organizations.pending']]: {
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.userpreferences.account']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
    ['none']: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.userpreferences.notifications']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.support.documentation']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.users.userId']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['get.users']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.users']]: {
    [OrganizationType.basic]: [],
    [OrganizationType.manufacturer]: [],
    [OrganizationType.sales_rep]: [],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.home']]: {
    [OrganizationType.basic]: [],
    [OrganizationType.manufacturer]: [],
    [OrganizationType.sales_rep]: [],
    [OrganizationType.frio]: [],
    ['none']: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.verificationRequests']]: {
    [OrganizationType.basic]: [Role.ADMIN],
    [OrganizationType.manufacturer]: [],
    [OrganizationType.sales_rep]: [Role.ADMIN],
    [OrganizationType.frio]: [Role.ADMIN],
  },
  [PermissionName['view.inventory']]: {
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.inventory.assign']]: {
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.inventory.sold']]: {
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['view.weatherData']]: {
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['edit.devices.info']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['edit.devices.settings']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['device.blinkup']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['device.ping']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['device.override']]: {
    [OrganizationType.basic]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.manufacturer]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.sales_rep]: [Role.ADMIN, Role.MEMBER],
    [OrganizationType.frio]: [Role.ADMIN, Role.MEMBER],
  },
  [PermissionName['edit.organization']]: {
    [OrganizationType.basic]: [Role.ADMIN],
    [OrganizationType.manufacturer]: [Role.ADMIN],
    [OrganizationType.sales_rep]: [Role.ADMIN],
    [OrganizationType.frio]: [Role.ADMIN],
  },
  [PermissionName['delete.organization']]: {
    [OrganizationType.sales_rep]: [Role.ADMIN],
    [OrganizationType.frio]: [Role.ADMIN],
  },
  [PermissionName['edit.organization.orgName']]: {
    [OrganizationType.basic]: [Role.ADMIN],
    [OrganizationType.manufacturer]: [Role.ADMIN],
    [OrganizationType.sales_rep]: [Role.ADMIN],
    [OrganizationType.frio]: [Role.ADMIN],
  },
  [PermissionName['edit.organization.inviteSameOrg']]: {
    [OrganizationType.basic]: [Role.ADMIN],
    [OrganizationType.manufacturer]: [Role.ADMIN],
    [OrganizationType.sales_rep]: [Role.ADMIN],
    [OrganizationType.frio]: [Role.ADMIN],
  },
  [PermissionName['edit.organization.inviteOtherOrg']]: {
    [OrganizationType.sales_rep]: [Role.ADMIN],
    [OrganizationType.frio]: [Role.MEMBER, Role.ADMIN],
  },
  [PermissionName['edit.organization.create']]: {
    [OrganizationType.sales_rep]: [Role.MEMBER, Role.ADMIN],
    [OrganizationType.frio]: [Role.MEMBER, Role.ADMIN],
  },
  [PermissionName['view.organization.pending_requests']]: {
    [OrganizationType.frio]: [Role.MEMBER, Role.ADMIN],
  },
  [PermissionName['edit.organization.pending_requests']]: {
    [OrganizationType.frio]: [Role.MEMBER, Role.ADMIN],
  },
  [PermissionName['edit.organization.orgType']]: {
    [OrganizationType.frio]: [Role.ADMIN],
  },
  [PermissionName['edit.organization.orgSalesRep']]: {
    [OrganizationType.frio]: [Role.ADMIN],
  },
  [PermissionName['edit.organization.orgShortName']]: {
    [OrganizationType.frio]: [Role.ADMIN],
  },
  [PermissionName['edit.organization.deleteUserSameOrg']]: {
    [OrganizationType.basic]: [Role.ADMIN],
    [OrganizationType.manufacturer]: [Role.ADMIN],
    [OrganizationType.sales_rep]: [Role.ADMIN],
    [OrganizationType.frio]: [Role.ADMIN],
  },
  [PermissionName['edit.organization.deleteUserOtherOrg']]: {
    [OrganizationType.sales_rep]: [Role.ADMIN],
    [OrganizationType.frio]: [Role.ADMIN],
  },
  [PermissionName['edit.organization.resendInvite']]: {
    [OrganizationType.basic]: [Role.ADMIN],
    [OrganizationType.manufacturer]: [Role.ADMIN],
    [OrganizationType.sales_rep]: [Role.ADMIN],
    [OrganizationType.frio]: [Role.ADMIN],
  },
  [PermissionName['edit.user.preferences']]: {
    [OrganizationType.basic]: [],
    [OrganizationType.manufacturer]: [],
    [OrganizationType.sales_rep]: [],
    [OrganizationType.frio]: [Role.ADMIN],
  },
  [PermissionName['edit.user.role']]: {
    [OrganizationType.basic]: [Role.ADMIN],
    [OrganizationType.manufacturer]: [Role.ADMIN],
    [OrganizationType.sales_rep]: [Role.ADMIN],
    [OrganizationType.frio]: [Role.ADMIN],
  },
  [PermissionName['edit.user.delete']]: {
    [OrganizationType.basic]: [Role.ADMIN],
    [OrganizationType.manufacturer]: [Role.ADMIN],
    [OrganizationType.sales_rep]: [Role.ADMIN],
    [OrganizationType.frio]: [Role.ADMIN],
  },
  [PermissionName['edit.user.phoneNumber']]: {
    [OrganizationType.basic]: [],
    [OrganizationType.manufacturer]: [],
    [OrganizationType.sales_rep]: [],
    [OrganizationType.frio]: [],
  },
};

const PAGE_VIEW_PERMISSIONS = {
  '/': PermissionName['view.devicegroups'], // Default view is 'devicegroups
  '/devicegroups': PermissionName['view.devicegroups'],
  '/devicegroups/new': PermissionName['view.devicegroups.new'],
  '/devicegroups/[deviceGroupId]': PermissionName['view.devicegroups.deviceGroupId'],
  '/devicegroups/[deviceGroupId]/settings': PermissionName['view.devicegroups.deviceGroupId.settings'],
  '/devices': PermissionName['view.devices'],
  '/devices/[deviceId]': PermissionName['view.devices.deviceId'],
  '/devices/[deviceId]/settings': PermissionName['view.devices.deviceId.settings'],
  '/organizations': PermissionName['view.organizations'],
  '/organizations/add': PermissionName['view.organizations.add'],
  '/organizations/pending': PermissionName['view.organizations.pending'],
  '/userpreferences/account': PermissionName['view.userpreferences.account'],
  '/userpreferences/notifications': PermissionName['view.userpreferences.notifications'],
  '/support/documentation': PermissionName['view.support.documentation'],
  '/inventory': PermissionName['view.inventory'],
  '/inventory/assign': PermissionName['view.inventory.assign'],
  '/inventory/bulk-mark-as-sold': PermissionName['view.inventory.sold'],
  '/weatherData': PermissionName['view.weatherData'],
  '/users/[userId]': PermissionName['view.users.userId'],
  '/users': PermissionName['view.users'],
  '/home': PermissionName['view.home'],
  '/verification-requests': PermissionName['view.verificationRequests'],
  // These are not real pages, but are used for permissions checks
  '/organizations/[organizationId].sameOrg': PermissionName['view.organizations.sameOrg'],
  '/organizations/[organizationId].otherOrg': PermissionName['view.organizations.otherOrg'],
};

export function hasPermission(permissionName: PermissionName, orgType: OrganizationType, role: Role) {
  const permissionsByOrgType = PERMISSIONS[permissionName];

  return !!permissionsByOrgType[orgType]?.includes(role as Role);
}

export function canView(urlPath: string, orgType: OrganizationType, role: Role) {
  const permissionName = PAGE_VIEW_PERMISSIONS[urlPath];
  const permissionsByOrgType = PERMISSIONS[permissionName];

  return !!permissionsByOrgType?.[orgType || 'none']?.includes(role as Role);
}

export const usePermissionsCognito = () => {
  const { user, isLoading } = useAuth();

  if (isLoading) return { isLoading: true };

  const permissionCheck = (permissionName: PermissionName) => {
    return hasPermission(permissionName, user?.organization?.organizationType as OrganizationType, user?.role as Role);
  };

  const viewCheck = (urlPath: string) => {
    return canView(urlPath, user?.organization?.organizationType, user?.role as Role);
  };

  return {
    hasPermission: permissionCheck,
    canView: viewCheck,
    isLoading: false,
  };
};
