import { Modal } from '../Modal/Modal';

interface IncompleteAddressModalProps {
  onAccept: () => void;
  isOpen: boolean;
  onChange: () => void;
  onClose: () => void;
  missingFields: string[];
}

export default function IncompleteAddressModal(props: IncompleteAddressModalProps) {
  const { isOpen, onChange, onClose, onAccept, missingFields } = props;
  return (
    <Modal
      isOpen={isOpen}
      onChange={onChange}
      title="Incomplete Address"
      primaryAction={onAccept}
      primaryText="Confirm"
      secondaryAction={onClose}
      secondaryText="Edit"
    >
      <div className="flex flex-col gap-2">
        <div>You are missing the following fields:</div>
        {missingFields.map((field) => (
          <div key={field} className="font-bold">
            {field}
          </div>
        ))}
        <div>
          Are you sure you want to continue? You may not receive the most accurate location-based weather services.
        </div>
      </div>
    </Modal>
  );
}
