import { CalendarDate } from '@internationalized/date';
import { DatePicker as NextUIDatePicker } from '@nextui-org/react';

interface DateInputProps {
  label?: string;
  onChange?: (string) => void;
  required?: boolean;
  errorMessage?: string;
  defaultValue?: CalendarDate;
  value?: CalendarDate;
}

export function DatePicker(props: DateInputProps) {
  const { label, onChange, required, errorMessage, defaultValue } = props;

  return (
    <NextUIDatePicker
      label={label}
      //eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      defaultValue={defaultValue}
      classNames={{ timeInput: 'text-base' }}
      isRequired={required}
      onChange={onChange}
      size="md"
      labelPlacement="inside"
      errorMessage={errorMessage}
    />
  );
}
