import React from 'react';
import { Field, Form } from 'react-final-form';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { PrimaryButton, SecondaryButton, TextInput } from 'app/core/design-system';
import axios from 'axios';

export async function verifySms({ code, userId }) {
  const response = await axios.post(
    `/api/users/${userId}/sms/verify`,
    { code },
    {
      withCredentials: true,
    },
  );
  return response.data;
}

export async function addSms({ phoneNumber, userId }) {
  const response = await axios.post(
    `/api/users/${userId}/sms`,
    { phoneNumber },
    {
      withCredentials: true,
    },
  );
  return response.data;
}

export function ConfirmPhoneNumber({ phoneNumber, onSuccess, userId }) {
  const verifySmsMutation = useMutation({
    mutationFn: verifySms,
    onSuccess: () => {
      onSuccess();
    },
  });

  const addSmsMutation = useMutation({
    mutationFn: addSms,
  });

  const handleVerifySms = async function handleVerifySms({ code }) {
    try {
      await verifySmsMutation.mutateAsync({
        code,
        userId,
      });
      toast('Phone number confirmed!');
    } catch (error) {
      toast.error(error.message, { autoClose: 20000 });
    }
  };

  const handleAddSms = async function handleAddSms() {
    try {
      await addSmsMutation.mutateAsync({
        phoneNumber,
        userId,
      });
      toast('Code sent!');
    } catch (error) {
      toast.error(error.message, { autoClose: 20000 });
    }
  };

  return (
    <div className="flex justify-center items-center w-full">
      <div className="flex flex-col gap-4 mt-4 w-full">
        <div className="p-2 rounded bg-blue-100 text-center outline outline-1 outline-blue-300">
          Please check your text messages for a confirmation code we just sent and enter it below.
        </div>
        <div className="flex flex-col gap-2 w-full">
          <Form
            onSubmit={handleVerifySms}
            render={({ handleSubmit, form, submitting }) => (
              <form onSubmit={handleSubmit} className="flex flex-col gap-2 w-full">
                <Field name="code">
                  {(props) => (
                    <TextInput
                      placeholder="Enter verification code"
                      value={props.input.value}
                      name={props.input.name}
                      onChange={props.input.onChange}
                    />
                  )}
                </Field>

                <div className="flex gap-2">
                  <PrimaryButton onClick={form.submit} disabled={submitting} width="full">
                    Confirm
                  </PrimaryButton>
                </div>
              </form>
            )}
          />
          <SecondaryButton onClick={handleAddSms} width="full">
            Resend code
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
}
