import { Field, Form } from 'react-final-form';
import { PrimaryButton } from '../../atoms/PrimaryButton/PrimaryButton';
import { SecondaryButton } from '../../atoms/SecondaryButton/SecondaryButton';
import { z } from 'zod';
import { useState } from 'react';
import { TextInput } from '../../atoms/TextInput/TextInput';
import { SingleSelect } from '../../atoms/SingleSelect/SingleSelect';
import { hasPermission, PermissionName } from 'app/core/hooks/usePermissions';
import { Role, User } from 'types';
import { timezones } from './timezones';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { Autocomplete } from '../../atoms/Autocomplete/Autocomplete';

const AccountDetailsSchema = z.object({
  first: z.string(),
  last: z.string(),
  timezone: z.string().nullable().optional(),
  role: z.string(),
});

function constructErrors(errors) {
  return errors?.reduce((acc, error) => {
    acc[error.path[0]] = error.message;

    return acc;
  }, {});
}

const ROLE_OPTIONS = [
  { value: Role.ADMIN, label: 'Admin - Can invite and modify users' },
  { value: Role.MEMBER, label: 'Member - Cannot invite or modify users' },
];

interface AccountDetailsProps {
  user: User;
  currentUser: User;
  onAccountUpdate: () => Promise<void>;
}

export async function updatePreferences({ details, userId }) {
  const response = await axios.patch(
    `/api/users/${userId}/preferences`,
    { ...details },
    {
      withCredentials: true,
    },
  );
  return response.data;
}

export function AccountDetails(props: AccountDetailsProps) {
  const { user, currentUser, onAccountUpdate } = props;
  const [hasErrors, setHasErrors] = useState(false);

  const handleValidate = function handleManualFormValidate(values) {
    try {
      AccountDetailsSchema.parse(values);
      setHasErrors(false);
    } catch (error) {
      setHasErrors(true);
      return constructErrors(error.errors);
    }
  };

  const updatePreferencesMutation = useMutation({
    mutationFn: updatePreferences,
    onSuccess: async () => {
      await onAccountUpdate();
    },
  });

  const handleSubmit = async function handleSubmit(values) {
    try {
      await updatePreferencesMutation.mutateAsync({
        details: values,
        userId: user.id,
      });
      toast('Updated preferences!');
    } catch (error) {
      toast.error(error.message, { autoClose: 20000 });
    }
  };

  const isSelf = user.id === currentUser.id;

  const initialValues = {
    first: user.preferences.first,
    last: user.preferences.last,
    timezone: user.preferences.timezone,
    role: user.role,
  };

  const disablePreferencesForm =
    !isSelf &&
    !hasPermission(
      PermissionName['edit.user.preferences'],
      currentUser.organization.organizationType,
      currentUser.role,
    );

  const disableRoleForm =
    isSelf ||
    !hasPermission(PermissionName['edit.user.role'], currentUser.organization.organizationType, currentUser.role);

  return (
    <div className="bg-gray-200 rounded-lg p-4 gap-2 flex flex-col">
      <h3>Update preferences</h3>
      <Form
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={handleValidate}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Field name="first">
              {(props) => (
                <TextInput
                  key="first"
                  label="First name"
                  {...props.input}
                  errorMessage={props.meta.error}
                  placeholder="Enter first name"
                  disabled={disablePreferencesForm}
                  size="sm"
                  autocapitalize="on"
                />
              )}
            </Field>
            <Field name="last">
              {(props) => (
                <TextInput
                  key="last"
                  label="Last name"
                  {...props.input}
                  errorMessage={props.meta.error}
                  placeholder="Enter last name"
                  disabled={disablePreferencesForm}
                  size="sm"
                  autocapitalize="on"
                />
              )}
            </Field>
            <Field name="timezone">
              {(props) => (
                <Autocomplete
                  key="timezone"
                  label="Timezone"
                  selectedItem={props.input.value}
                  onChange={props.input.onChange}
                  errorMessage={props.meta.error}
                  disabled={disablePreferencesForm}
                  options={timezones.map((timezone) => ({ value: timezone, label: timezone }))}
                />
              )}
            </Field>
            <Field name="role">
              {(props) => (
                <SingleSelect
                  label="Role"
                  placeholder="Select a role"
                  selectedItem={props.input.value}
                  onChange={props.input.onChange}
                  options={ROLE_OPTIONS}
                  size="sm"
                  disabled={disableRoleForm}
                />
              )}
            </Field>
            <div className="flex flex-col md:flex-row gap-2 justify-end w-full col-span-1 md:col-span-2">
              <div className="w-full md:w-fit">
                <SecondaryButton
                  width="full"
                  onClick={form.reset}
                  disabled={!form.getState().dirty || disablePreferencesForm}
                >
                  Cancel
                </SecondaryButton>
              </div>
              <div className="w-full md:w-fit">
                <PrimaryButton
                  width="full"
                  onClick={form.submit}
                  disabled={hasErrors || !form.getState().dirty || disablePreferencesForm}
                >
                  Save Changes
                </PrimaryButton>
              </div>
            </div>
          </form>
        )}
      />
    </div>
  );
}
