'use client';

import React from 'react';
import { NextUIProvider } from '@nextui-org/react';
import { useRouter } from 'next/navigation';
import { ErrorBoundary } from 'react-error-boundary';
import { AuthProvider } from '../app/core/context/AuthContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { TableContextWrapper } from 'app/core/context/TableContext';
import Head from 'next/head';
import type { AppProps } from 'next/app';
import { ErrorFallback } from 'app/core/components/ErrorFallback';
import { ScrollWrapper } from 'app/core/context/ScrollContext';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { Toast } from 'app/core/common/Toast';
import { TooltipWrapper } from 'app/core/design-system';

import 'react-toastify/dist/ReactToastify.css';
import 'app/core/styles/index.css';

const queryClient = new QueryClient();

export default withLDProvider({
  clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID,
})(function App({ Component, pageProps }: AppProps) {
  const router = useRouter();
  return (
    <NextUIProvider navigate={router.push}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <TableContextWrapper>
              <ScrollWrapper>
                <TooltipWrapper>
                  <Toast />
                  <Head>
                    <meta
                      name="viewport"
                      content="width=device-width, initial-scale=1, interactive-widget=resizes-content"
                    />
                  </Head>
                  <Component {...pageProps} />
                </TooltipWrapper>
              </ScrollWrapper>
            </TableContextWrapper>
          </ErrorBoundary>
        </AuthProvider>
      </QueryClientProvider>
    </NextUIProvider>
  );
});
