import type { FC, ReactNode } from 'react';
import { Button } from '@nextui-org/react';
import classNames from 'classnames';
import { Size } from '../../types';
import { getComponentSize } from '../../utils';

type ButtonVariant = 'primary' | 'secondary' | 'danger' | 'text';

export interface BaseButtonProps {
  onClick?: (event) => void;
  children: ReactNode;
  disabled?: boolean;
  width?: 'full' | 'fit';
  title?: string;
  variant?: ButtonVariant;
  size?: Size;
  loading?: boolean;
  isIconOnly?: boolean;
}

const VARIANT_CLASSES = {
  primary: 'bg-blue-500 hover:bg-blue-400 active:bg-blue-500 text-white',
  secondary: 'bg-white hover:bg-gray-50 active:bg-white border-2 border-gray-300 focus:outline-none',
  danger: 'bg-red-600 text-white hover:bg-red-500 active:bg-red-600',
  text: 'hover:text-blue-400 min-w-0 active:text-blue-500 text-blue-500 bg-transparent p-0 h-fit w-fit',
};

export const BaseButton: FC<BaseButtonProps> = (props) => {
  const { width = 'fit', variant = 'primary', disabled, size = 'sm', loading = false, onClick, ...rest } = props;
  return (
    <Button
      {...rest}
      onPress={onClick}
      isLoading={loading}
      isDisabled={disabled}
      className={classNames(
        'text-base',
        VARIANT_CLASSES[variant],
        width === 'fit' ? 'w-fit' : 'w-full',
        variant !== 'text' && getComponentSize(size),
      )}
      disableAnimation={variant === 'text'}
    />
  );
};
