import { AnyObject, Field, Form } from 'react-final-form';
import { TextInput } from '../../atoms/TextInput/TextInput';
import { SingleSelect } from '../../atoms/SingleSelect/SingleSelect';
import { PrimaryButton } from '../../atoms/PrimaryButton/PrimaryButton';
import classNames from 'classnames';
import { countryConfigs } from './countryConfigs';
import { AddressType } from 'types';
import { SecondaryButton } from '../../atoms/SecondaryButton/SecondaryButton';
import { useState } from 'react';
import { find } from 'lodash';
import { Autocomplete } from '../../atoms/Autocomplete/Autocomplete';

interface ManualAddressFormProps {
  onSubmit: (address: AddressType) => void;
  onValidate: (values) => AnyObject | Promise<AnyObject>;
  userAddress: AddressType;
  submitText?: string;
  cancelText?: string;
  hasErrors: boolean;
  onCancel: () => void;
}

export function ManualAddressForm(props: ManualAddressFormProps) {
  const {
    onSubmit,
    onValidate,
    userAddress,
    submitText = 'Submit',
    hasErrors,
    cancelText = 'Cancel',
    onCancel,
  } = props;
  const country = userAddress?.country || 'United States';
  const [addressConfig, setAddressConfig] = useState(() => {
    return find(countryConfigs, (config) => config.value === country)?.addressConfig || countryConfigs.US.addressConfig;
  });

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={userAddress}
      validate={onValidate}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit} className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {addressConfig.map((field) => (
            <div
              key={field.name}
              className={classNames('col-span-1', field.width === 'half' ? 'md:col-span-1' : 'md:col-span-2')}
            >
              <Field name={field.name}>
                {(props) => {
                  if (field.type === 'autocomplete') {
                    return (
                      <Autocomplete
                        label={field.label}
                        selectedItem={props.input.value}
                        onChange={props.input.onChange}
                        required={field.required}
                        errorMessage={props.meta.error}
                        options={field.options.map((option) => ({
                          label: option.label,
                          value: option.value,
                        }))}
                      />
                    );
                  } else {
                    return (
                      <TextInput
                        key={field.name}
                        label={field.label}
                        autocomplete={field.name}
                        {...props.input}
                        required={field.required}
                        errorMessage={props.meta.error}
                        placeholder={field.label}
                      />
                    );
                  }
                }}
              </Field>
            </div>
          ))}
          <div className="col-span-1 md:col-span-1">
            <Field name="country">
              {(props) => (
                <SingleSelect
                  label="Country"
                  required
                  selectedItem={props.input.value}
                  onChange={(value) => {
                    props.input.onChange(value);
                    form.change('addressLevel1', '');
                    if (value) {
                      const newConfig = find(countryConfigs, (config) => config.value === value)?.addressConfig;
                      setAddressConfig(newConfig);
                    }
                  }}
                  options={Object.keys(countryConfigs).map((country) => ({
                    value: countryConfigs[country].value,
                    label: countryConfigs[country].label,
                  }))}
                />
              )}
            </Field>
          </div>
          <div className="flex flex-col md:flex-row gap-2 justify-end w-full col-span-1 md:col-span-2">
            <div className="w-full md:w-fit">
              <PrimaryButton width="full" onClick={form.submit} disabled={hasErrors}>
                {submitText}
              </PrimaryButton>
            </div>
            <div className="w-full md:w-fit">
              <SecondaryButton width="full" onClick={onCancel}>
                {cancelText}
              </SecondaryButton>
            </div>
          </div>
        </form>
      )}
    />
  );
}
