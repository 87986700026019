import {
  CreateOrganizationRequest,
  JoinOrganizationRequest,
  NotificationsConfig,
  Onboarding,
  Organization,
  OrganizationType,
  UserInvite,
  Userpreferences,
  User as DBUser,
} from '@prisma/client';
import { RowData } from '@tanstack/react-table';
import { ControlMode, Device } from 'types/device';

// https://github.com/TanStack/table/blob/main/docs/api/core/column-def.md#meta
declare module '@tanstack/table-core' {
  // eslint-disable-next-line
  interface ColumnMeta<TData extends RowData, TValue> {
    headerCellClass: string;
    showOnMobile: boolean;
    frioHeaderClass: string;
    hideOnMobile?: boolean;
    hideOnDesktop?: boolean;
    cellAlignment?: 'left' | 'right' | 'center';
    disableSort?: boolean;
  }
}

export interface DashboardDeviceGroup {
  id: number;
  name: string;
  description: string;
  address: DeprecatedAddressType;
  organization: number;
}

export interface DevicesTableDataShape {
  deviceId?: string;
  connectionStatus?: boolean;
  alarmCount?: number;
  heater?: boolean;
  name?: any;
  serialNumber?: string;
  deviceGroupName?: any;
  state?: any;
  alarms?: any[];
  activeAlarmsCount?: number;
  orgShortName?: string;
  organizationName?: string;
  organizationNameSalesRep?: string;
  disconnectsInLast24Hours?: number;
  connectionChangeTime?: string;
  isActive?: boolean;
  deviceGroup?: {
    id?: number;
    name?: string;
  };
}

export interface DeviceGroupsTableDataShape {
  connectionStatus?: boolean;
  alarmCount?: number;
  name?: any;
  activeAlarmsCount?: number;
  orgShortName?: string;
  organizationName?: string;
  organizationType?: OrganizationType;
  organizationNameSalesRep?: string;
  countAlarmsColor?: string;
  countHeaterOn?: number;
  devices?: number;
  maxCellDeviceDisconnectsInLast24Hours?: number;
  maxNonCellDeviceDisconnectsInLast24Hours?: number;
  earliestCellDeviceDisconnectDate: Date;
  earliestNonCellDeviceDisconnectDate: Date;
  countActive?: number;
  state?: any;
  id?: number;
}

export interface RequestAccessType {
  email: string;
  firstName: string;
  lastName: string;
  organizationName: string;
}

export interface DevicesTableProps {
  devices?: DashboardDevice[];
  hideInactiveFilter?: boolean;
  user?: User;
  sortKey?: string;
}

export interface OneSettingTextBoxNumberV2Type {
  hr: boolean;
  label: string;
  customTestId: string;
  input: any;
  meta: any;
  description: string;
  min: number;
  max: number;
  step: number;
  units: string;
  customKey: any;
  isRelative: boolean;
}

export interface TabLabelType {
  label: string;
  tab: 'general' | 'location' | 'cloudcontrol' | 'devicecontrol' | 'alarms' | 'other';
  highlight?: boolean;
  className?: string;
  children?: any;
  href?: string;
}

export interface TabContentType {
  children?: any;
  visible: boolean;
  className?: string;
}

export interface SettingsTabLabelsType {
  tabs: any[];
  tab: string;
  navTab: any;
}

export interface AlarmTableProps {
  data: any | undefined;
  gridCols: string;
  children?: any;
  onRowClick?: (cellId: string | undefined) => void;
  sortProperties?: any[];
  customKey?: any;
  onSort?: any;
  columnHelper?: any;
}

export interface TableHeadingType {
  className: string;
  columnTitles: string[];
  columnIds: number[];
  TableHeadingCell: any;
}

export enum Role {
  ADMIN = 'admin',
  MEMBER = 'member',
}

export interface HwSettings {
  weatherAlgo?: string;
  holdOnMinutes?: number;
  controlMode?: ControlMode;
}

// Abstraction for the dashboard UI
export interface DashboardDevice extends Device {
  disconnectsInLast24Hours: number;
  connectionChangeTime?: string;
  alarms: any[];
  address: DeprecatedAddressType;
  state: string;
  connectionStatus: boolean;
  alarmCount: number;
  deviceGroupName: string;
  heater: boolean;
  controlMode: ControlMode;
  name: string;
  shortName: string;
  cloudDeviceSettings: any;
  serialNumber: string;
  hwSettings?: HwSettings;
  latestReadingTime?: string;
}

export interface SmsRecipientType {
  phone: string;
  timezone: string;
  notificationsConfig: any;
}

export interface AlertListItemTypeDeprecated {
  deviceId: string;
  deviceName: string;
  deviceGroupId: number;
  deviceGroupName: string;
  alertData: string;
}

export interface AlarmTypeToggleType {
  alarmType: string;
  notificationType: string;
  onSuccess: any;
  value: any;
  disabled?: boolean;
  alsoUnchecked?: boolean;
}

export interface UpdateDeviceHwType {
  deviceId: string;
  settings: any;
}

export interface OverrideType {
  state: string;
  lengthSeconds: number;
  source: string;
  time_start: number;
}

export interface OverrideCardType {
  override: OverrideType;
  cancelClicked: any;
  isLoading: boolean;
}

export interface DeleteDeviceGroupFormProps {
  onError?: (error: string) => void;
  onSuccess?: (message: string) => void;
  onCancel?: () => void;
  devicegroup: {
    id: number;
    name: string;
  };
}

export interface PopupMenuDeviceGroupsType {
  devicegroup: DeviceGroupType;
  onSuccess?: (message: string) => void;
  onClose?: () => void;
}

export interface PopupMenuTestResetType {
  onSuccess?: () => void;
  onClose?: () => void;
  isSpotCheckInProgress?: boolean;
  status: {
    isConnected?: boolean;
  };
}

export interface GetDevicegroupInput {
  deviceGroupId: number;
}

export interface UpdateDevicegroupInput {
  id: number;
  name: string;
  address: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
}

export interface DeviceGroupType {
  id: any;
  name: any;
  organizationId: any;
  _count: any;
}

export interface DeprecatedAddressType {
  id: number;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export interface AddressType {
  id: string;
  addressLine1: string;
  addressLine2: string;
  addressLevel2: string;
  addressLevel1: string;
  postalCode: string;
  country: Country;
}

export type Country = 'United States' | 'Canada';

export interface DeviceSettingsLinkType {
  deviceId: string;
  tab: string;
}

export interface DevicegroupSettingsLinkType {
  deviceGroupId: string;
  tab: string;
}

export interface SecureNavType {
  Icon?: any;
  isSelected: boolean;
  text: string;
  href: any;
  justify?: string;
  badgeText?: string;
  onClick?: () => void;
}

export interface LoadingType {
  height?: number;
  width?: number;
  pad?: boolean;
}

export interface FrioAccordionProps {
  open?: boolean;
  setOpen?: (newState) => void;
  children?: any;
}

export interface DevicegroupFormProps {
  devicegroup: any;
  onSuccess?: (any) => void;
}

export interface HeaterStatusProps {
  heater: boolean;
  heaterChangeTime?: string;
  controlState?: string;
  children?: any;
  showLabel?: boolean;
  isDeviceActive: boolean;
}

export interface RadioButtonType {
  item?: any;
  checked?: boolean;
  onChange?: any;
}

export interface OverrideHeaterInput {
  state: boolean;
  deviceId: string;
  lengthSeconds: number;
}

export interface GfepTestInput {
  deviceId: string;
}

export interface OverrideHeaterType {
  state: boolean;
  lengthSeconds: number;
  source: string;
  time_start: number;
}

export interface TitleBreadcrumbsProps {
  path: TitleBreadcrumbsType[];
}

export interface TitleBreadcrumbsType {
  url: string;
  label: string;
}

export interface ShortAccReq {
  organizationName: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface User extends Omit<DBUser, 'role' | 'createdAt' | 'updatedAt' | 'preferencesId' | 'organizationId'> {
  role?: Role;
  createdAt?: Date;
  preferences?: {
    email: string;
    first?: string;
    last?: string;
    phone?: string;
    phoneConfirmed?: boolean;
    timezone?: string;
  };
  organization?: {
    organizationType: OrganizationType;
  };
  createOrgRequests?: CreateOrganizationRequest[];
  joinOrgRequests?: JoinOrganizationRequest[];
  userInvites?: UserInvite[];
}

export interface SpotCheckRun {
  id: string;
  spotCheckVersionId: string;
  createdAt: Date;
  runStartTime?: Date;
  runStopTime?: Date;
  gfepTestStopTime?: Date;
  heaterStartTime?: Date;
  heaterStopTime?: Date;
  status: SpotCheckRunStatus;
  latestAnalysisResult?: SpotCheckResult;
  spotCheckAnalysisResults?: SpotCheckAnalysisResult[];
}

export interface SpotCheckAnalysisResult {
  result: SpotCheckResult;
  analysisTime: Date;
}

export enum SpotCheckResult {
  passed = 'Check Passed',
  failed = 'Check Failed',
  issues_identified = 'Issues Identified',
}

export enum SpotCheckRunStatus {
  in_progress = 'in_progress',
  failed = 'failed',
  success = 'success',
  cancelled = 'cancelled',
}

export type UserWithOrg = {
  role: string;
  id?: number;
  organization?: Organization;
  preferences?: Userpreferences & {
    notificationsConfig: NotificationsConfig;
  };
  onboarding?: Onboarding;
};
