import { createContext, useContext, useState, useEffect } from 'react';

type ScrollContext = {
  resetScroll: () => void;
  pageScroll: Record<string, number>;
  addPageScroll: (pageId: string, value: number) => void;
};
const ScrollContext = createContext<ScrollContext>(null);

export const useScrollTracker = (pageId) => {
  const { addPageScroll, pageScroll } = useContext(ScrollContext);
  useEffect(() => {
    const onScroll = (e) => {
      addPageScroll(pageId, e.target.scrollTop);
    };

    const scrollParent = document.getElementById(pageId)?.parentElement;

    if (scrollParent) {
      if (pageScroll[pageId]) {
        scrollParent.scrollTop = pageScroll[pageId];
      }

      scrollParent.addEventListener('scroll', onScroll);

      return () => scrollParent.removeEventListener('scroll', onScroll);
    }
  }, []);
};

export function ScrollWrapper({ children }) {
  const [pageScroll, setPageScroll] = useState({});

  const resetScroll = () => {
    setPageScroll({});
  };

  const addPageScroll = (pageId, value) => {
    setPageScroll((prevState) => ({ ...prevState, [pageId]: value }));
  };

  const sharedState = {
    resetScroll,
    pageScroll,
    addPageScroll,
  };

  return <ScrollContext.Provider value={sharedState}>{children}</ScrollContext.Provider>;
}

export function useScrollContext() {
  return useContext(ScrollContext);
}
