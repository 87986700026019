import { useMutation } from '@tanstack/react-query';
import { PrimaryButton } from 'app/core/design-system/atoms/PrimaryButton/PrimaryButton';
import { TextButton } from 'app/core/design-system/atoms/TextButton/TextButton';
import { TextInput } from 'app/core/design-system/atoms/TextInput/TextInput';
import axios from 'axios';
import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { toast } from 'react-toastify';
import { z } from 'zod';

const SerialNumberFormSchema = z.object({
  serialNumber: z.string(),
});

function constructErrors(errors) {
  return errors?.reduce((acc, error) => {
    acc[error.path[0]] = error.message;

    return acc;
  }, {});
}

export async function createVerificationRequest(serialNumber: string) {
  const response = await axios.post(
    '/api/public/join-organization-requests',
    { requestedSerialNumber: serialNumber },
    {
      withCredentials: true,
    },
  );
  return response.data;
}

export function SerialNumberView({ onSubmit, previous }: { onSubmit?: () => Promise<void>; previous: () => void }) {
  const [hasErrors, setHasErrors] = useState(false);

  const createRequestMutation = useMutation({
    mutationFn: createVerificationRequest,
  });

  const handleFormSubmit = async function handleFormSubmit({ serialNumber }: { serialNumber: string }) {
    try {
      await createRequestMutation.mutateAsync(serialNumber);
      await onSubmit?.();
      toast('Request submitted!');
    } catch (error) {
      if (error.response && error.response.status < 500) {
        toast.error(error.response.data.message, { autoClose: 20000 });
      } else {
        toast.error('Something went wrong. If the problem persists, contact support.', { autoClose: 20000 });
      }
    }
  };

  const handleFormValidate = function handleFormValidate(values) {
    try {
      SerialNumberFormSchema.parse(values);
      setHasErrors(false);
    } catch (error) {
      setHasErrors(true);
      return constructErrors(error.errors);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <Form
        onSubmit={handleFormSubmit}
        initialValues={{ serialNumber: null }}
        validate={handleFormValidate}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} className="flex flex-col gap-2">
            <div className="flex gap-2">
              <Field name="serialNumber">
                {(props) => (
                  <TextInput
                    key="serialNumber"
                    label="Serial Number"
                    {...props.input}
                    required
                    errorMessage={props.meta.modified && props.meta.error}
                    placeholder="Enter serial number"
                  />
                )}
              </Field>
              <PrimaryButton
                width="fit"
                onClick={form.submit}
                disabled={hasErrors}
                size="sm"
                loading={createRequestMutation?.isPending}
              >
                Submit
              </PrimaryButton>
            </div>
            <div className="w-full flex justify-between">
              <TextButton onClick={previous} width="fit">
                Back
              </TextButton>
            </div>
          </form>
        )}
      />
    </div>
  );
}
