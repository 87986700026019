import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

export function SortIcon({ isSorted }) {
  return (
    <div className="flex flex-col">
      <ChevronUpIcon
        data-testid="sort-icon-up"
        className={classNames('h-4 w-4 -my-1', isSorted === 'asc' ? 'text-black' : 'text-gray-400')}
      />
      <ChevronDownIcon
        data-testid="sort-icon-down"
        className={classNames('h-4 w-4 -my-1', isSorted === 'desc' ? 'text-black' : 'text-gray-400')}
      />
    </div>
  );
}
