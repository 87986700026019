'use client';

import React from 'react';
import { default as NextLink } from 'next/link';
import { Link } from '@nextui-org/react';
import Image from 'next/image';
import poweredByFrioLogo from 'public/poweredByFrio.svg';
import { NavItem, NavItemGroup, renderNavGroup, renderNavItem } from '../common';

export interface SidebarProps {
  navItems: Array<NavItem | NavItemGroup>;
  logoSrc: string;
}

export function Sidebar({ navItems, logoSrc }) {
  return (
    <div className="h-dvh pb-2 bg-white flex flex-col w-full">
      <div className="relative flex flex-1 flex-col text-black overflow-y-auto gap-2">
        <Link href="/" as={NextLink} data-testid="business-logo-link">
          <div className="flex justify-center h-16 w-full border-b-[1px] border-b-gray-200">
            <Image priority={true} width={64} height={64} src={logoSrc} alt={'Company logo'} />
          </div>
        </Link>
        <div className="flex flex-col gap-2 px-6">
          {navItems.map((item) => {
            if (item.items) {
              return renderNavGroup(item);
            }

            return renderNavItem(item);
          })}
        </div>
      </div>
      <div className="flex justify-center text-center flex-col items-center">
        <Link href="https://www.frio.co" as={NextLink} target="_blank" data-testid="frio-home-link">
          <Image src={poweredByFrioLogo} alt="powered by Frio logo" width={100} />
        </Link>
        <div className="text-base p-2">© 2024 Frio, LLC</div>
      </div>
    </div>
  );
}
