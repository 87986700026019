import { FC } from 'react';
import { XIcon } from '@heroicons/react/outline';

export type TagColors = 'gray' | 'green' | 'red' | 'yellow';
interface TagProps {
  onClear?: () => void;
  children: string;
  color?: TagColors;
}

const COLOR_MAPPING = {
  gray: {
    bg: 'bg-gray-300',
    text: 'text-gray-500',
  },
  green: {
    bg: 'bg-green-800',
    text: 'text-white',
  },
  red: {
    bg: 'bg-red-600',
    text: 'text-white',
  },
  yellow: {
    bg: 'bg-yellow-400',
    text: 'text-white',
  },
};

export const Tag: FC<TagProps> = (props) => {
  const { children, onClear, color = 'gray' } = props;
  return (
    <div
      className={`flex items-center justify-center rounded-full w-fit px-2 py-1 text-xs gap-2 ${COLOR_MAPPING[color].bg} h-fit min-w-6`}
    >
      <span className={`${COLOR_MAPPING[color].text} text-center whitespace-nowrap`}>{children}</span>
      {onClear && (
        <button onClick={onClear}>
          <XIcon className="h-3 w-3 text-gray-500" />
          <span className="hidden">Clear</span>
        </button>
      )}
    </div>
  );
};
