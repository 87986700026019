import { RadioGroup, Radio, Divider } from '@nextui-org/react';
import { AddressDisplay } from './AddressDisplay';
import { AddressType } from 'types';
import { Modal } from '../Modal/Modal';

interface VerifyAddressModalProps {
  enteredAddress: AddressType;
  suggestedAddress: AddressType;
  onAccept: () => void;
  isOpen: boolean;
  onChange: () => void;
  onClose: () => void;
  verifiedAddressSelection: 'entered' | 'suggested';
  setVerifiedAddressSelection: (selection: 'entered' | 'suggested') => void;
}

export default function VerifyAddressModal(props: VerifyAddressModalProps) {
  const {
    enteredAddress,
    suggestedAddress,
    setVerifiedAddressSelection,
    verifiedAddressSelection,
    onClose,
    onChange,
    isOpen,
    onAccept,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onChange={onChange}
      size="2xl"
      title="Verify address"
      primaryAction={onAccept}
      primaryText="Accept"
      secondaryAction={onClose}
      secondaryText="Cancel"
    >
      <RadioGroup onValueChange={setVerifiedAddressSelection} value={verifiedAddressSelection}>
        <div className="flex flex-col md:flex-row gap-4 justify-between">
          <div>
            <Radio value="entered">
              <div className="font-bold">Entered Address:</div>
            </Radio>
            <AddressDisplay {...enteredAddress} />
          </div>
          <div className="hidden md:block">
            <Divider orientation="vertical" className="h-28" />
          </div>
          <div>
            <Radio value="suggested">
              <div className="font-bold">Suggested Address:</div>
            </Radio>
            <AddressDisplay {...suggestedAddress} />
          </div>
        </div>
      </RadioGroup>
    </Modal>
  );
}
