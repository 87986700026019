import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

// This component should only be rendered once at the top of the component tree
export const Toast = () => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined' && window) {
      setIsMobile(window.matchMedia('(max-width: 480px)').matches);
    }
  }, []);

  const position = isMobile ? 'bottom-center' : 'top-right';
  return (
    <ToastContainer
      position={position}
      className="flex justify-center items-center flex-col"
      toastClassName="min-h-fit p-1 w-fit mb-4 sm:p-2 sm:min-h-[var(--toastify-toast-min-height)]"
      bodyClassName="text-sm sm:text-base"
      limit={3}
    />
  );
};
