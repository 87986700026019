import axios from 'axios';
import React, { useState } from 'react';
import VerifyAddressModal from './VerifyAddressModal';
import IncompleteAddressModal from './IncompleteAddressModal';
import { z } from 'zod';
import { AddressType, Country } from 'types';
import { PostalCodeSearchForm } from './PostalCodeSearchForm';
import { ManualAddressForm } from './ManualAddressForm';
import { toast } from 'react-toastify';
import InvalidAddressModal from './InvalidAddressModal';
import InvalidPostalCodeModal from './InvalidPostalCodeModal';
import { useModalDisclosure } from '../Modal/useModalDisclosure';

const AddressFormSchema = z.object({
  addressLine1: z.string().nullable().optional(),
  addressLine2: z.string().nullable().optional(),
  addressLevel2: z.string({
    required_error: 'This field is required',
    invalid_type_error: 'This field is required',
  }),
  addressLevel1: z.string({
    required_error: 'This field is required',
    invalid_type_error: 'This field is required',
  }),
  postalCode: z.string({
    required_error: 'This field is required',
  }),
  country: z.string({
    required_error: 'This field is required',
  }),
});

interface AddressFormProps {
  onSubmit: (address: AddressType) => void;
  onCancel?: () => void;
  submitText: string;
  cancelText?: string;
  includePostalCodeSearch?: boolean;
  initialAddress?: AddressType;
}

export async function getAddressByPostalcode(postalCode: string, country: Country) {
  const response = await axios.get(`/api/locations/search?postalCode=${postalCode}&country=${country}`, {
    withCredentials: true,
  });
  return response.data;
}

export async function validateAddress(address: AddressType) {
  const response = await axios.post('/api/locations/validate', {
    withCredentials: true,
    address,
  });
  return response.data;
}

function constructErrors(errors) {
  return errors?.reduce((acc, error) => {
    acc[error.path[0]] = error.message;

    return acc;
  }, {});
}

const blankAddress = {
  addressLine1: null,
  addressLine2: null,
  addressLevel2: null,
  addressLevel1: null,
  postalCode: null,
  country: null,
};

export function AddressForm(props: AddressFormProps) {
  const { onSubmit, submitText, includePostalCodeSearch = true, initialAddress, onCancel, cancelText } = props;
  const [userAddress, setUserAddress] = useState<AddressType>(initialAddress || ({} as AddressType));
  const [showManualForm, setShowManualForm] = useState(() => {
    return !includePostalCodeSearch || (!!initialAddress && Object.keys(initialAddress).length > 0);
  });
  const [verifiedAddressSelection, setVerifiedAddressSelection] = useState<'entered' | 'suggested'>('entered');
  const [hasErrors, setHasErrors] = useState(false);
  const [suggestedAddress, setSuggestedAddress] = useState<AddressType>({} as AddressType);
  const {
    isOpen: isVerifyAddressModalOpen,
    open: openVerifyAddressModal,
    onChange: verifyAddressModalChange,
    close: closeVerifyAddressModal,
  } = useModalDisclosure();
  const {
    isOpen: isIncompleteAddressModalOpen,
    open: openIncompleteAddressModalOpen,
    onChange: incompleteAddressModalChange,
    close: closeIncompleteAddressModal,
  } = useModalDisclosure();

  const {
    isOpen: isInvalidAddressModalOpen,
    open: openInvalidAddressModalOpen,
    onChange: invalidAddressModalChange,
    close: closeInvalidAddressModal,
  } = useModalDisclosure();

  const {
    isOpen: isInvalidPostalCodeModalOpen,
    open: openInvalidPostalCodeModalOpen,
    onChange: invalidPostalCodeModalChange,
    close: closeInvalidPostalCodeModal,
  } = useModalDisclosure();

  const verifyAddress = async function verifyAddress(address: AddressType) {
    try {
      const response = await validateAddress(address);

      if (response.verdict === 'valid') {
        const newAddress = {
          ...blankAddress,
          ...address,
        };
        onSubmit(newAddress);
      } else if (response.verdict === 'incomplete') {
        setSuggestedAddress(response.suggestedAddress);
        openVerifyAddressModal();
      } else if (response.verdict === 'invalid_postalcode') {
        openInvalidPostalCodeModalOpen();
      } else {
        openInvalidAddressModalOpen();
      }
    } catch (error) {
      toast.error('Unable to validate the address. Please try again.');
    }
  };

  const handleManualFormSubmit = async function handleManualFormSubmit(address: AddressType) {
    setUserAddress(address);
    const hasMissingFields = ['addressLine1'].some((key) => !address[key]);
    if (hasMissingFields) {
      openIncompleteAddressModalOpen();
      return;
    }
    await verifyAddress(address);
  };

  const handleManualFormValidate = function handleManualFormValidate(values) {
    try {
      AddressFormSchema.parse(values);
      setHasErrors(false);
    } catch (error) {
      setHasErrors(true);
      return constructErrors(error.errors);
    }
  };

  const handleManualFormCancel = function handleManualFormReset() {
    setShowManualForm(false);
    onCancel?.();
  };

  const handlePostalCodeSubmit = async function handlePostalCodeSubmit(postalCode: string, country: Country) {
    try {
      const address = await getAddressByPostalcode(postalCode, country);
      setUserAddress(address);
      setShowManualForm(true);
    } catch (error) {
      openInvalidPostalCodeModalOpen();
    }
  };

  // It seems like the react-final-form doesn't submit null if a user deletes the contents of a field, so we need to manually set it to null
  const handleFinalSubmit = function handleFinalSubmit() {
    const newAddress = {
      ...blankAddress,
      ...userAddress,
    };
    onSubmit(newAddress);
  };

  const handleVerifySubmit = function handleFinalSubmit() {
    const newAddress = {
      ...blankAddress,
      ...(verifiedAddressSelection === 'entered' ? userAddress : suggestedAddress),
    };
    onSubmit(newAddress);
  };

  return (
    <div className="flex flex-col gap-4">
      <VerifyAddressModal
        onAccept={handleVerifySubmit}
        isOpen={isVerifyAddressModalOpen}
        enteredAddress={userAddress}
        suggestedAddress={suggestedAddress}
        onChange={verifyAddressModalChange}
        onClose={closeVerifyAddressModal}
        verifiedAddressSelection={verifiedAddressSelection}
        setVerifiedAddressSelection={setVerifiedAddressSelection}
      />
      <IncompleteAddressModal
        isOpen={isIncompleteAddressModalOpen}
        onAccept={async () => {
          closeIncompleteAddressModal();
          await verifyAddress(userAddress);
        }}
        onChange={incompleteAddressModalChange}
        onClose={closeIncompleteAddressModal}
        missingFields={['Street address']}
      />
      <InvalidAddressModal
        isOpen={isInvalidAddressModalOpen}
        onAccept={handleFinalSubmit}
        onChange={invalidAddressModalChange}
        onClose={closeInvalidAddressModal}
        invalidAddress={userAddress}
      />
      <InvalidPostalCodeModal
        isOpen={isInvalidPostalCodeModalOpen}
        onChange={invalidPostalCodeModalChange}
        onClose={closeInvalidPostalCodeModal}
      />
      {includePostalCodeSearch && !showManualForm && <PostalCodeSearchForm onSubmit={handlePostalCodeSubmit} />}
      {showManualForm && (
        <ManualAddressForm
          onValidate={handleManualFormValidate}
          onSubmit={handleManualFormSubmit}
          userAddress={userAddress}
          submitText={submitText}
          hasErrors={hasErrors}
          onCancel={handleManualFormCancel}
          cancelText={cancelText}
        />
      )}
    </div>
  );
}
