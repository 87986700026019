'use client';

import { toast } from 'react-toastify';
import { LoginForm } from 'app/core/design-system';
import { useAuth } from 'app/core/context/AuthContext';
import { useRouter } from 'next/navigation';
import { UnauthorizedError } from 'app/core/errors/UnauthorizedError';
import { PasswordResetRequiredError } from '../../../cognito-service/cognito-service';
import { resetPassword } from '../../../util/apiFunctions';
import { useMutation as tanstackUseMutation } from '@tanstack/react-query';

export function Login() {
  const router = useRouter();
  const resetPasswordMutation = tanstackUseMutation({
    mutationFn: resetPassword,
  });
  const auth = useAuth();
  const handleLogin = async function handleLogin(values) {
    try {
      await auth.login(values.email, values.password);
      router.refresh();
    } catch (error) {
      if (error instanceof PasswordResetRequiredError) {
        await resetPasswordMutation.mutateAsync({ email: values.email });
        toast.error(`Your password has expired. Please check your email for a link to reset your password.`, {
          autoClose: false,
        });
      } else if (error instanceof UnauthorizedError) {
        toast.error('Incorrect username or password');
      } else {
        toast.error('There was an issue during login.');
      }
    }
  };

  return <LoginForm onLogin={handleLogin} />;
}
