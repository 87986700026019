import { CustomButton } from '../CustomButton';
import { TextButton } from 'app/core/design-system/atoms/TextButton/TextButton';

interface InstallerViewProps {
  next: () => void;
  previous: () => void;
}
export function InstallerView({ next, previous }: InstallerViewProps) {
  return (
    <div className="flex gap-2 flex-col">
      <CustomButton onClick={next}>Continue to connect a Frio S1 device and run a Spot Check</CustomButton>
      <div className="flex gap-2 justify-start w-full">
        <TextButton onClick={previous} width="fit">
          Back
        </TextButton>
      </div>
    </div>
  );
}
