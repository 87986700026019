import { AddressType } from 'types';
import { AddressDisplay } from './AddressDisplay';
import { Modal } from '../Modal/Modal';

interface InvalidAddressModalProps {
  onAccept: () => void;
  isOpen: boolean;
  onChange: () => void;
  onClose: () => void;
  invalidAddress: AddressType;
}

export default function InvalidAddressModal(props: InvalidAddressModalProps) {
  const { isOpen, onChange, onClose, onAccept, invalidAddress } = props;
  return (
    <Modal
      isOpen={isOpen}
      onChange={onChange}
      primaryText="Confirm"
      primaryAction={onAccept}
      secondaryText="Edit"
      secondaryAction={onClose}
      title="Confirm address"
    >
      <div className="flex flex-col gap-2">
        <div>
          It looks like your address may be missing information to enable us to validate it. Before continuing, please
          confirm that your address is correct:
        </div>
        <div className="font-bold">
          <AddressDisplay {...invalidAddress} />
        </div>
        <div>Are you sure you want to continue?</div>
      </div>
    </Modal>
  );
}
