import { TextButton } from 'app/core/design-system/atoms/TextButton/TextButton';
import { CustomButton } from '../../Onboarding/CustomButton';

export function OrgChoiceView({ onJoinExistingOrg, onCreateOrg, previous }) {
  return (
    <div className="flex flex-col gap-2">
      <CustomButton onClick={onJoinExistingOrg}>I would like to join an existing organization</CustomButton>
      <CustomButton onClick={onCreateOrg}>I would like to create a new organization</CustomButton>
      <div className="flex gap-2 justify-start w-full">
        <TextButton onClick={previous} width="fit">
          Back
        </TextButton>
      </div>
    </div>
  );
}
