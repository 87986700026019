import { PrimaryButton } from '../../atoms/PrimaryButton/PrimaryButton';
import { Tag, TagColors } from '../../atoms/Tag/Tag';
import { useState } from 'react';
import { EnterPhoneNumber } from './EnterPhoneNumber';
import { ConfirmPhoneNumber } from './ConfirmPhoneNumber';
import { SecondaryButton } from '../../atoms/SecondaryButton/SecondaryButton';
import { Modal } from '../Modal/Modal';
import { useModalDisclosure } from '../Modal/useModalDisclosure';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { User } from 'types';
import { DangerButton } from '../../atoms/DangerButton/DangerButton';
import { useAuth } from '../../../context/AuthContext';
import { deleteUser } from '../../../../users/components/UsersTable';
import { useRouter } from 'next/navigation';
import { hasPermission, PermissionName } from '../../../hooks/usePermissions';
import { resetPassword } from '../../../../../util/apiFunctions';

type PhoneNumberViews = 'verify-number' | 'edit-number';

export async function removeSms({ userId }) {
  const response = await axios.delete(`/api/users/${userId}/sms`, {
    withCredentials: true,
  });
  return response.data;
}

interface SettingsProps {
  user: User;
  currentUser: User;
  onAccountUpdate: () => Promise<void>;
}

export function Settings(props: SettingsProps) {
  const { user, currentUser, onAccountUpdate } = props;
  const { logout } = useAuth();
  const router = useRouter();
  const { phoneConfirmed, phone } = user.preferences;
  const [isEditing, setIsEditing] = useState(false);
  const removeSmsMutation = useMutation({
    mutationFn: removeSms,
    onSuccess: async () => {
      await onAccountUpdate();
    },
  });
  const deleteUserMutation = useMutation({
    mutationFn: deleteUser,
  });

  const handleRemoveSms = async function handleRemoveSms() {
    try {
      await removeSmsMutation.mutateAsync({ userId: user.id });

      toast('Phone number removed!');
    } catch (error) {
      toast.error(error.message, { autoClose: 20000 });
    } finally {
      closeRemovePhoneModal();
    }
  };

  const handleDeleteUser = async function handleDeleteUser() {
    try {
      await deleteUserMutation.mutateAsync({ userId: user.id });

      toast('User deleted!');
      if (user.id === currentUser.id) {
        logout();
        router.push('/login');
      } else {
        router.replace('/users');
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 20000 });
    } finally {
      closeDeleteUserModal();
    }
  };

  const resetPasswordMutation = useMutation({
    mutationFn: resetPassword,
    onSuccess: async () => {
      await onAccountUpdate();
    },
  });

  const handleResetPassword = async function handleResetPassword() {
    try {
      await resetPasswordMutation.mutateAsync({ userId: user.id });
      toast(`Reset instructions were sent to ${user.preferences.email}, please check your inbox.`);
    } catch (error) {
      toast.error(error.message, { autoClose: 20000 });
    }
  };

  const [view, setView] = useState<PhoneNumberViews>('edit-number');
  const hasConfirmedPhone = phoneConfirmed && !!phone;
  const viewMapping = {
    'edit-number': (
      <EnterPhoneNumber
        onSuccess={async () => {
          await onAccountUpdate();
          setIsEditing(false);
        }}
        userId={user.id}
      />
    ),
    'verify-number': (
      <ConfirmPhoneNumber
        onSuccess={async () => {
          await onAccountUpdate();
          setIsEditing(false);
        }}
        phoneNumber={phone}
        userId={user.id}
      />
    ),
  };

  const {
    isOpen: isRemovePhoneModalOpen,
    open: openRemovePhoneModal,
    onChange: removePhoneModalChange,
    close: closeRemovePhoneModal,
  } = useModalDisclosure();

  const {
    isOpen: isDeleteUserModalOpen,
    open: openDeleteUserModal,
    onChange: deleteUserModalChange,
    close: closeDeleteUserModal,
  } = useModalDisclosure();

  let tagColor: TagColors = 'gray';
  let verifiedText = 'Not set';
  if (hasConfirmedPhone) {
    tagColor = 'green';
    verifiedText = 'Verified';
  } else if (phone && !phoneConfirmed) {
    tagColor = 'yellow';
    verifiedText = 'Unverified';
  }

  const isSelf = user.id === currentUser?.id;

  return (
    <div>
      <Modal
        isOpen={isRemovePhoneModalOpen}
        onChange={removePhoneModalChange}
        primaryAction={handleRemoveSms}
        primaryText="Confirm"
        secondaryAction={closeRemovePhoneModal}
        secondaryText="Cancel"
        title="Confirm phone removal"
      >
        <div className="pb-4">Are you sure you want to remove this phone number?</div>
      </Modal>
      <Modal
        isOpen={isDeleteUserModalOpen}
        onChange={deleteUserModalChange}
        primaryAction={handleDeleteUser}
        primaryText="Confirm"
        secondaryAction={closeDeleteUserModal}
        secondaryText="Cancel"
        title="Confirm deletion"
      >
        <div className="pb-4">Are you sure you want to delete this user?</div>
      </Modal>
      <div className="flex flex-col gap-2 justify-between">
        {isSelf && (
          <div className="flex p-4 gap-2 w-full justify-between bg-gray-200 rounded-lg flex-col md:flex-row">
            <div>
              <p>Password</p>
              <p className="text-sm">Set a unique password to protect your account</p>
            </div>
            <div className="w-full md:w-fit">
              <PrimaryButton onClick={handleResetPassword} width="full">
                Reset Password
              </PrimaryButton>
            </div>
          </div>
        )}
        <div className="flex p-4 gap-2 w-full justify-between bg-gray-200 rounded-lg flex-col md:flex-row">
          <div className="w-full md:w-1/2">
            <div className="flex gap-2">
              <p>Phone number</p>
              <div className="flex gap-2">
                {phone && <span>{phone}</span>}
                <Tag color={tagColor}>{verifiedText}</Tag>
              </div>
            </div>
            <p className="text-sm">The phone number where you would like to receive SMS notifications</p>
          </div>
          {isSelf && (
            <div className="flex flex-col gap-2 w-full md:w-1/2 items-end">
              {isEditing && viewMapping[view]}
              {isEditing ? (
                <div className="w-full">
                  <SecondaryButton onClick={() => setIsEditing(false)} width="full">
                    Cancel
                  </SecondaryButton>
                </div>
              ) : (
                <div className="flex flex-col md:flex-row justify-end gap-2 w-full">
                  <div className="w-full md:w-fit">
                    <PrimaryButton
                      onClick={() => {
                        setIsEditing(true);
                        setView('edit-number');
                      }}
                      width="full"
                    >
                      {phone ? 'Edit phone number' : 'Add a phone number'}
                    </PrimaryButton>
                  </div>
                  {!phoneConfirmed && (
                    <div className="w-full md:w-fit">
                      <SecondaryButton
                        onClick={() => {
                          setIsEditing(true);
                          setView('verify-number');
                        }}
                        width="full"
                      >
                        Enter verification code
                      </SecondaryButton>
                    </div>
                  )}
                  {hasConfirmedPhone && isSelf && (
                    <div className="w-full md:w-fit">
                      <DangerButton onClick={openRemovePhoneModal} width="full">
                        Remove
                      </DangerButton>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {(hasPermission(
          PermissionName['edit.user.delete'],
          currentUser.organization.organizationType,
          currentUser.role,
        ) ||
          isSelf) && (
          <div className="flex p-4 gap-2 w-full justify-between bg-gray-200 rounded-lg flex-col md:flex-row">
            <div>
              <p>Delete Account</p>
            </div>
            <div className="w-full md:w-fit">
              <DangerButton onClick={openDeleteUserModal} width="full">
                Delete
              </DangerButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
