import { useEffect, useState } from 'react';
import type { TextInputProps } from '../../atoms/TextInput/TextInput';
import { TextInput } from '../../atoms/TextInput/TextInput';

interface DebouncedTextInputProps extends TextInputProps {
  debounce?: number;
}
export function DebouncedTextInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: DebouncedTextInputProps) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => {
      clearTimeout(timeout);
    };
  }, [value]);

  return <TextInput {...props} value={value} onChange={(value) => setValue(value)} />;
}
