import { TextButton } from 'app/core/design-system/atoms/TextButton/TextButton';
import { CustomButton } from '../../Onboarding/CustomButton';

export function StartView({ onSelectOrgChoice, onSelectSerialNumber, previous }) {
  return (
    <div className="flex flex-col gap-2">
      <CustomButton onClick={onSelectSerialNumber}>Enter the serial number of your Frio S1 device</CustomButton>
      <CustomButton onClick={onSelectOrgChoice}>I do not have a serial number</CustomButton>
      {previous && (
        <div className="flex gap-2 justify-start w-full">
          <TextButton onClick={previous} width="fit">
            Back
          </TextButton>
        </div>
      )}
    </div>
  );
}
