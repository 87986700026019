import { Field, Form } from 'react-final-form';
import { TextInput } from '../../atoms/TextInput/TextInput';
import { PrimaryButton } from '../../atoms/PrimaryButton/PrimaryButton';
import { countryConfigs } from './countryConfigs';
import { SingleSelect } from '../../atoms/SingleSelect/SingleSelect';
import { Country } from 'types';

interface PostalCodeSearchFormProps {
  onSubmit: (postalCode: string, country: Country) => void;
}
export function PostalCodeSearchForm(props: PostalCodeSearchFormProps) {
  const { onSubmit } = props;
  const handleSubmit = (values) => {
    onSubmit(values.postalCode, values.country);
  };
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ country: 'United States' }}
      render={({ handleSubmit, form }) => (
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col md:flex-row gap-2 items-end">
            <div className="w-full md:w-2/3">
              <Field name="postalCode">
                {(props) => <TextInput label="Enter Postal Code" {...props.input} placeholder="e.g. 10001" />}
              </Field>
            </div>
            <div className="w-full md:w-1/3">
              <Field name="country">
                {(props) => (
                  <SingleSelect
                    label="Country"
                    selectedItem={props.input.value}
                    onChange={props.input.onChange}
                    options={Object.keys(countryConfigs).map((country) => ({
                      value: countryConfigs[country].value,
                      label: countryConfigs[country].label,
                    }))}
                  />
                )}
              </Field>
            </div>
            <div className="w-full md:w-fit">
              <PrimaryButton onClick={form.submit} width="full">
                Search
              </PrimaryButton>
            </div>
          </div>
        </form>
      )}
    />
  );
}
