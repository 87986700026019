import classNames from 'classnames';
import { AddressType } from 'types';
import { isEmpty } from 'lodash';

interface AddressDisplayProps extends Omit<AddressType, 'id'> {
  alignment?: 'left' | 'right';
}
export function AddressDisplay(props: AddressDisplayProps) {
  const { addressLine1, addressLine2, addressLevel2, addressLevel1, postalCode, country, alignment = 'left' } = props;

  if (!props || isEmpty(props)) {
    return <span>None</span>;
  }
  return (
    <div className={classNames('flex flex-col whitespace-nowrap', alignment === 'right' ? 'text-right' : 'text-left')}>
      {addressLine1 && <div>{addressLine1}</div>}
      {addressLine2 && <div>{addressLine2}</div>}
      <div>
        {addressLevel2}, {addressLevel1} {postalCode}
      </div>
      <div>{country}</div>
    </div>
  );
}
