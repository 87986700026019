import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { Input } from '@nextui-org/input';
import { useState, type FC } from 'react';

export type TextInputType = 'text' | 'password';
import { Size } from '../../types';
import { getComponentSize } from '../../utils';
import classNames from 'classnames';

export interface TextInputProps {
  /**
   * Change handler called when the user enters input
   * @param value The user's input
   */
  onChange?: (value: string) => void;
  /**
   * Clear handled called when the user presses the clear button to delete the input
   */
  onClear?: () => void;
  /**
   * The value of the input, if controlled
   */
  value?: string;
  /**
   * Placeholder text
   */
  placeholder?: string;
  /**
   * The icon to render if the input is empty
   */
  leftIcon?: JSX.Element;
  /**
   * The name attribute
   */
  name?: string;
  /**
   * The label for the input
   */
  label?: string;
  /**
   * The autocomplete attribute according to the HTML standard
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
   */
  autocomplete?: string;
  /**
   * If the input is required in a form
   */
  required?: boolean;
  errorMessage?: string;
  /**
   * If the input be a password input
   */
  password?: boolean;
  disabled?: boolean;
  inputType?: TextInputType;
  size?: Size;
  /**
   * If the first character of each sentence should be capitalized automatically.
   */
  autocapitalize?: 'off' | 'on';
}

export const TextInput: FC<TextInputProps> = (props) => {
  const {
    onClear,
    onChange,
    value,
    leftIcon,
    label,
    required,
    errorMessage,
    disabled,
    inputType = 'text',
    size = 'sm',
    autocapitalize = 'off',
    ...rest
  } = props;

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const toggleVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const handleChange = function handleChange(newValue: string) {
    onChange?.(newValue);
  };

  const handleClear = function handleClear() {
    onClear?.();
  };
  return (
    <div className="relative w-full">
      <Input
        {...rest}
        labelPlacement="inside"
        size="sm"
        // NextUI components have different heights depending upon if they have a label or not.
        // Force them to always be the same height as if they had a label.
        classNames={{ inputWrapper: classNames(!label && getComponentSize(size)), input: 'text-medium' }}
        label={label}
        onValueChange={handleChange}
        {...(onClear && { onClear: handleClear })}
        value={value}
        startContent={leftIcon}
        isRequired={required}
        errorMessage={errorMessage}
        isInvalid={!!errorMessage}
        isDisabled={disabled}
        autoCapitalize={autocapitalize}
        {...(inputType === 'password' && {
          endContent: (
            <button className="focus:outline-none flex" type="button" onClick={toggleVisibility}>
              {isPasswordVisible ? (
                <EyeOffIcon className="text-2xl h-6 text-default-400 pointer-events-none" />
              ) : (
                <EyeIcon className="text-2xl h-6 text-default-400 pointer-events-none flex" />
              )}
            </button>
          ),
        })}
        type={inputType === 'password' && isPasswordVisible ? 'text' : inputType}
      />
    </div>
  );
};
