'use client';

import { User } from 'types';
import { Card, CardBody } from '@nextui-org/card';
import { AccountDetails } from './AccountDetails';
import { Settings } from './Settings';

interface AccountSettingsProps {
  user: User;
  currentUser: User;
  onAccountUpdate?: () => Promise<void>;
}

export function AccountSettings(props: AccountSettingsProps) {
  const { user, currentUser, onAccountUpdate } = props;

  return (
    <div className="flex w-full flex-col">
      <Card>
        <CardBody>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col mb-8">
              <div className="text-md">
                {user.preferences.first} {user.preferences.last}
              </div>
              <div className="text-sm">{user.preferences.email}</div>
            </div>
            <AccountDetails user={user} currentUser={currentUser} onAccountUpdate={onAccountUpdate} />
            <Settings user={user} currentUser={currentUser} onAccountUpdate={onAccountUpdate} />
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
