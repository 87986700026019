import { Link } from '@nextui-org/react';
import classNames from 'classnames';
import { default as NextLink } from 'next/link';
import { HTMLAttributeAnchorTarget, ReactNode } from 'react';
import { Tag } from '../../atoms/Tag/Tag';

export interface NavItem {
  label: string;
  href: string;
  target?: HTMLAttributeAnchorTarget;
  isActive?: boolean;
  hidden?: boolean;
  icon?: ReactNode;
  group?: string;
  badgeText?: string;
  onClick?: () => void;
}

export interface NavItemGroup {
  items: NavItem[];
  title: string;
  hidden?: boolean;
}

export function renderNavItem(item: NavItem) {
  const { isActive, hidden, href, label, icon, badgeText, target, onClick } = item;
  if (hidden) {
    return null;
  }
  return (
    <div
      className={classNames('hover:bg-gray-300 rounded-[14px]', isActive && 'bg-blue-500 hover:bg-blue-500')}
      key={href}
    >
      <Link
        href={href}
        as={NextLink}
        className={classNames(
          'text-black flex items-center justify-between gap-1 py-1.5 px-3 text-base whitespace-nowrap',
          isActive && 'text-white',
        )}
        size="lg"
        onClick={onClick}
        target={target}
      >
        <div className="flex gap-2 items-center">
          {icon}
          {label}
        </div>
        {!!badgeText && (
          <div className={'ml-1'}>
            <Tag color="red">{badgeText}</Tag>
          </div>
        )}
      </Link>
    </div>
  );
}

export function renderNavGroup(group: NavItemGroup) {
  const { items, title, hidden } = group;

  if (hidden) {
    return null;
  }
  return (
    <div key={title}>
      <span className="text-black text-sm">{title}</span>
      {items.map((item) => renderNavItem(item))}
    </div>
  );
}
