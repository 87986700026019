import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { PasswordResetRequiredError } from '../../../cognito-service/cognito-service';
import { UnauthorizedError } from '../errors/UnauthorizedError';
import { UserWithOrg } from 'types';

const AuthContext: React.Context<AuthContextValue> = createContext(null);

export interface AuthContextValue {
  user: UserWithOrg;
  isLoading: boolean;
  error?: Error;
  login: (email, password) => void;
  logout: () => void;
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const getMe = async () => {
      const response = await axios.get('/api/users/me', {
        withCredentials: true,
      });
      setUser(response.data);
    };

    setIsLoading(true);
    getMe()
      .catch(() => {
        setIsLoading(false);
        setUser(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [isLoggedIn]);

  const login = async (email: string, password: string) => {
    try {
      await axios.post(
        `/api/auth/login`,
        {
          email,
          password,
        },
        {
          withCredentials: true,
        },
      );
      setIsLoggedIn(true);
    } catch (error) {
      if (error.response?.data?.passwordResetRequired) {
        throw new PasswordResetRequiredError();
      } else if (error.response?.status === 401) {
        throw new UnauthorizedError('Invalid email or password');
      }
      throw error;
    }
  };

  const logout = async () => {
    await axios.post('/api/auth/logout', {}, { withCredentials: true });
    setIsLoggedIn(false);
  };

  const contextValue: AuthContextValue = useMemo(
    () => ({ user, isLoading, login, logout }),
    [user, isLoading, login, logout],
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
}

export function useAuth(): AuthContextValue {
  return useContext(AuthContext);
}
