import { Select as NextUISelect, SelectItem as NextUISelectItem } from '@nextui-org/react';
import { SelectItem } from '../SingleSelect/SingleSelect';
import { Size } from '../../types';
import classNames from 'classnames';
import { getComponentSize } from '../../utils';

interface MultiSelectProps {
  selectedItems?: string[];
  label?: string;
  onChange?: ([string]) => void;
  options: SelectItem[];
  required?: boolean;
  placeholder?: string;
  emptyPlaceholder?: string;
  isLoading?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  size?: Size;
}

export function MultiSelect(props: MultiSelectProps) {
  const {
    label,
    selectedItems,
    onChange,
    options,
    required,
    placeholder,
    emptyPlaceholder = 'No Items',
    isLoading,
    errorMessage,
    disabled,
    size = 'sm',
  } = props;
  const handleChange = (e) => {
    const items = e.target.value ? e.target.value.split(',') : [];
    onChange?.(items);
  };

  return (
    <NextUISelect
      label={label}
      isRequired={required}
      {...(selectedItems && { selectedKeys: selectedItems })}
      {...(onChange && { onChange: handleChange })}
      labelPlacement="inside"
      // NextUI components have different heights depending upon if they have a label or not.
      // Force them to always be the same height as if they had a label.
      classNames={{ trigger: classNames(!label && getComponentSize(size), 'text-base'), value: 'text-base' }}
      isLoading={isLoading}
      isDisabled={options.length === 0 || disabled}
      placeholder={options.length === 0 ? emptyPlaceholder : placeholder}
      errorMessage={errorMessage}
      isInvalid={!!errorMessage}
      selectionMode="multiple"
      size={size}
    >
      {options.map((option) => (
        <NextUISelectItem value={option.value} key={option.value} classNames={{ title: 'text-base' }}>
          {option.label}
        </NextUISelectItem>
      ))}
    </NextUISelect>
  );
}
