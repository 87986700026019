import { Modal } from '../Modal/Modal';

interface InvalidPostalCodeModalProps {
  isOpen: boolean;
  onChange: () => void;
  onClose: () => void;
}

export default function InvalidAddressModal(props: InvalidPostalCodeModalProps) {
  const { isOpen, onChange, onClose } = props;
  return (
    <Modal
      isOpen={isOpen}
      onChange={onChange}
      title="Error validating postal code"
      primaryAction={onClose}
      primaryText="Close"
    >
      <div>
        It looks like you may have entered an invalid postal code. Please fix the address you entered before proceeding
      </div>
    </Modal>
  );
}
