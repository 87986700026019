import classNames from 'classnames';
import type { FC, HTMLAttributeAnchorTarget, ReactNode } from 'react';
import { Link as NextUILink } from '@nextui-org/react';
import { cloneElement } from 'react';
import { Size } from '../../types';
import { getComponentSize } from '../../utils';

type LinkVariant = 'primary' | 'secondary' | 'danger' | 'text';

interface LinkProps {
  onClick?: () => void;
  href?: string;
  children: ReactNode;
  disabled?: boolean;
  width?: 'full' | 'fit';
  rightIcon?: JSX.Element;
  as?: 'a' | React.ElementType;
  variant?: LinkVariant;
  size?: Size;
  target?: HTMLAttributeAnchorTarget;
}

const VARIANT_CLASSES = {
  primary: 'bg-blue-500 hover:bg-blue-400 active:bg-blue-500 text-white rounded-xl px-4',
  secondary:
    'bg-white hover:bg-gray-50 active:bg-white border-2 border-gray-300 focus:outline-none rounded-xl text-black px-4',
  danger: 'bg-red-600 hover:bg-red-500 active:bg-red-600 rounded-xl text-white px-4',
  text: 'text-blue-500 hover:text-blue-400 active:text-blue-500 text-blue-500',
};

export const Link: FC<LinkProps> = (props) => {
  const {
    width = 'fit',
    children,
    rightIcon,
    href,
    as: Component = 'a',
    disabled,
    variant = 'text',
    size = 'sm',
    ...rest
  } = props;

  return (
    <NextUILink
      as={Component}
      isDisabled={disabled}
      className={classNames(
        'text-base',
        VARIANT_CLASSES[variant],
        width === 'fit' ? 'w-fit' : 'w-full',
        'flex items-center justify-center lg:whitespace-nowrap',
        variant !== 'text' && getComponentSize(size),
      )}
      size="sm"
      href={href || '#'}
      {...(rightIcon && {
        anchorIcon: cloneElement(rightIcon, { className: 'ml-2 h-3 w-3 text-gray-500' }),
        showAnchorIcon: !!rightIcon,
      })}
      {...rest}
    >
      {children}
    </NextUILink>
  );
};
