import { useMutation } from '@tanstack/react-query';
import { PrimaryButton } from 'app/core/design-system/atoms/PrimaryButton/PrimaryButton';
import { TextButton } from 'app/core/design-system/atoms/TextButton/TextButton';
import { TextInput } from 'app/core/design-system/atoms/TextInput/TextInput';
import axios from 'axios';
import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { useAuth } from '../../../../context/AuthContext';

const CreateOrgRequestFormSchema = z.object({
  orgName: z.string(),
});

function constructErrors(errors) {
  return errors?.reduce((acc, error) => {
    acc[error.path[0]] = error.message;

    return acc;
  }, {});
}

export async function createOrgRequest(orgName: string) {
  const response = await axios.post(
    '/api/public/create-organization-requests',
    {
      requestedOrganizationName: orgName,
    },
    {
      withCredentials: true,
    },
  );
  return response.data;
}

export function CreateOrgView({ onSubmit, previous }: { onSubmit?: () => Promise<void>; previous: () => void }) {
  const [hasErrors, setHasErrors] = useState(false);
  const { user } = useAuth();

  const createOrgRequestMutation = useMutation({
    mutationFn: createOrgRequest,
  });

  const handleFormSubmit = async function handleFormSubmit({ orgName }: { orgName: string }) {
    try {
      await createOrgRequestMutation.mutateAsync(orgName);
      await onSubmit?.();
      toast('Request submitted!');
    } catch (error) {
      toast.error(error.message, { autoClose: 20000 });
    }
  };

  const handleFormValidate = function handleFormValidate(values) {
    try {
      CreateOrgRequestFormSchema.parse(values);
      setHasErrors(false);
    } catch (error) {
      setHasErrors(true);
      return constructErrors(error.errors);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <Form
        onSubmit={handleFormSubmit}
        initialValues={{ orgName: user?.preferences.companyName }}
        validate={handleFormValidate}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} className="flex flex-col gap-2">
            <div className="flex gap-2">
              <Field name="orgName">
                {(props) => (
                  <TextInput
                    key="orgName"
                    label="Organization name"
                    {...props.input}
                    required
                    errorMessage={props.meta.modified && props.meta.error}
                    placeholder="Enter the name for your organization"
                  />
                )}
              </Field>
              <PrimaryButton
                width="fit"
                onClick={form.submit}
                disabled={hasErrors}
                loading={createOrgRequestMutation?.isPending}
                size="sm"
              >
                Submit
              </PrimaryButton>
            </div>
            <div className="w-full flex justify-between">
              <TextButton onClick={previous} width="fit">
                Back
              </TextButton>
            </div>
          </form>
        )}
      />
    </div>
  );
}
