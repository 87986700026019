import { Modal as NextUIModal, ModalBody, ModalContent, ModalFooter, ModalHeader } from '@nextui-org/react';
import { ReactNode } from 'react';
import { PrimaryButton } from '../../atoms/PrimaryButton/PrimaryButton';
import { SecondaryButton } from '../../atoms/SecondaryButton/SecondaryButton';

interface ModalProps {
  title?: string;
  children?: ReactNode;
  primaryText?: string;
  primaryAction?: () => void;
  primaryButtonDisabled?: boolean;
  secondaryText?: string;
  secondaryAction?: () => void;
  isOpen: boolean;
  onChange: () => void;
  size?: 'sm' | 'md' | 'lg' | '2xl' | 'full';
  backdrop?: 'opaque' | 'transparent' | 'blur';
  placement?: 'auto' | 'top' | 'bottom' | 'center' | 'top-center';
}
export function Modal(props: ModalProps) {
  const {
    title,
    children,
    primaryText,
    primaryAction,
    primaryButtonDisabled,
    secondaryText,
    secondaryAction,
    isOpen,
    onChange,
    size = 'md',
    backdrop = 'opaque',
    placement = 'center',
  } = props;
  return (
    <NextUIModal
      isOpen={isOpen}
      onOpenChange={onChange}
      size={size}
      backdrop={backdrop}
      placement={placement}
      scrollBehavior="inside"
    >
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          {secondaryText && <SecondaryButton onClick={secondaryAction}>{secondaryText}</SecondaryButton>}
          {primaryText && (
            <PrimaryButton disabled={primaryButtonDisabled} onClick={primaryAction}>
              {primaryText}
            </PrimaryButton>
          )}
        </ModalFooter>
      </ModalContent>
    </NextUIModal>
  );
}
