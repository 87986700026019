import { Select as NextUISelect, SelectItem as NextUISelectItem } from '@nextui-org/react';
import { Size } from '../../types';
import { getComponentSize } from '../../utils';
import classNames from 'classnames';

export interface SelectItem {
  value: string;
  label: string;
}

interface SingleSelectProps {
  selectedItem?: string;
  label?: string;
  onChange?: (string) => void;
  options: SelectItem[];
  required?: boolean;
  placeholder?: string;
  emptyPlaceholder?: string;
  isLoading?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  size?: Size;
}

export function SingleSelect(props: SingleSelectProps) {
  const {
    label,
    selectedItem,
    onChange,
    options,
    required,
    placeholder,
    emptyPlaceholder = 'No Items',
    isLoading,
    errorMessage,
    disabled,
    size = 'sm',
  } = props;
  const handleChange = (e) => {
    onChange?.(e.target.value);
  };

  return (
    <NextUISelect
      label={label}
      isRequired={required}
      {...(selectedItem && { selectedKeys: [selectedItem] })}
      {...(onChange && { onChange: handleChange })}
      labelPlacement="inside"
      // NextUI components have different heights depending upon if they have a label or not.
      // Force them to always be the same height as if they had a label.
      classNames={{ trigger: classNames(!label && getComponentSize(size), 'text-base'), value: 'text-base' }}
      isLoading={isLoading}
      isDisabled={options.length === 0 || disabled}
      placeholder={options.length === 0 ? emptyPlaceholder : placeholder}
      errorMessage={errorMessage}
      isInvalid={!!errorMessage}
      size={size}
    >
      {options.map((option) => (
        <NextUISelectItem value={option.value} key={option.value} classNames={{ title: 'text-base' }}>
          {option.label}
        </NextUISelectItem>
      ))}
    </NextUISelect>
  );
}
