'use client';

import React, { useMemo, useState } from 'react';
import { useRouter } from 'next/navigation';
import { User } from 'types';
import { PermissionName, usePermissionsCognito } from 'app/core/hooks/usePermissions';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import {
  Column,
  DangerButton,
  Modal,
  PrimaryButton,
  Table,
  Tag,
  TagColors,
  useModalDisclosure,
} from 'app/core/design-system';
import { toast } from 'react-toastify';
import { useAuth } from 'app/core/context/AuthContext';
import { recursiveSort, useTableContext } from 'app/core/context/TableContext';
import { resetPassword } from '../../../util/apiFunctions';

export async function resendInvite({ userId }) {
  const response = await axios.post(`/api/users/${userId}/resend-invite`, {
    withCredentials: true,
  });
  return response.data;
}

export async function deleteUser({ userId }) {
  return axios.delete(`/api/users/${userId}`, {
    withCredentials: true,
  });
}

async function cancelInvite({ inviteId }) {
  return axios.patch(
    `/api/users/invite/${inviteId}`,
    {
      status: 'cancelled',
    },
    {
      withCredentials: true,
    },
  );
}

export type AvailableHiddenColumns = 'organization.name';

type UserStatus = 'Verified' | 'Unverified' | 'Invite Pending' | 'Verification Pending';

interface UserWithStatus extends User {
  status: UserStatus;
  memberSince?: string;
}

interface UsersTableProps {
  users: User[];
  pendingInvites: any[];
  refetch: () => void;
  columnsToHide?: AvailableHiddenColumns[];
  globalFilter?: string;
}

export function UsersTable({ users = [], pendingInvites, refetch, columnsToHide, globalFilter }: UsersTableProps) {
  const { hasPermission } = usePermissionsCognito();
  const { user: currentUser, logout } = useAuth();
  const { tableInfo, setSortingState, setPaginationState } = useTableContext('users');

  const router = useRouter();
  const [userIdToResendInvite, setUserIdToResendInvite] = useState<number | undefined>();
  const [userIdToDelete, setUserIdToDelete] = useState<number | undefined>();
  const [userIdToCancelInvite, setUserIdToCancelInvite] = useState<number | undefined>();
  const [userIdToResetPassword, setUserIdToResetPassword] = useState<number | undefined>();
  const {
    isOpen: isResendInviteModalOpen,
    open: openResendInviteModal,
    onChange: resendInviteModalChange,
    close: closeResendInviteModal,
  } = useModalDisclosure();
  const {
    isOpen: isDeleteUserModalOpen,
    open: openDeleteUserModal,
    onChange: deleteUserModalChange,
    close: closeDeleteUserModal,
  } = useModalDisclosure();
  const {
    isOpen: isCancelInviteModalOpen,
    open: openCancelInviteModal,
    onChange: cancelInviteModalChange,
    close: closeCancelInviteModal,
  } = useModalDisclosure();
  const {
    isOpen: isResetPasswordModalOpen,
    open: openResetPasswordModal,
    onChange: resetPasswordModalChange,
    close: closeResetPasswordModal,
  } = useModalDisclosure();

  const resendInviteMutation = useMutation({
    mutationFn: resendInvite,
  });

  const handleResendInvite = async function handleResendInvite() {
    try {
      await resendInviteMutation.mutateAsync({
        userId: userIdToResendInvite,
      });

      toast(`Invite sent!`);
    } catch (error) {
      toast.error(error.message, { autoClose: 20000 });
    } finally {
      setUserIdToResendInvite(undefined);
      closeResendInviteModal();
    }
  };

  const deleteUserMutation = useMutation({
    mutationFn: deleteUser,
  });

  const handleDeleteUser = async function handleDeleteUser() {
    try {
      await deleteUserMutation.mutateAsync({
        userId: userIdToDelete,
      });
      toast('Successfully deleted user');
      if (currentUser.id === userIdToDelete) {
        logout();
        router.push('/login');
      } else {
        refetch();
      }
    } catch (error) {
      toast.error(error.message, { autoClose: 20000 });
    } finally {
      setUserIdToDelete(undefined);
      closeDeleteUserModal();
    }
  };

  const cancelInviteMutation = useMutation({
    mutationFn: cancelInvite,
    onSuccess: async () => {
      await refetch();
    },
  });

  const handleCancelInvite = async function handleCancelInvite() {
    try {
      const pendingInvite = pendingInvites.find((invite) => invite.invitedUserId == userIdToCancelInvite);
      await cancelInviteMutation.mutateAsync({
        inviteId: pendingInvite.id,
      });
      toast('Successfully cancelled invite');
    } catch (error) {
      toast.error(error.message, { autoClose: 20000 });
    } finally {
      setUserIdToCancelInvite(undefined);
      closeCancelInviteModal();
    }
  };

  const resetPasswordMutation = useMutation({
    mutationFn: resetPassword,
  });

  const handleResetPassword = async function handleResetPassword() {
    try {
      await resetPasswordMutation.mutateAsync({
        userId: userIdToResetPassword,
      });
      toast('Successfully reset password');
    } catch (error) {
      toast.error(error.message, { autoClose: 20000 });
    } finally {
      setUserIdToResetPassword(undefined);
      closeResetPasswordModal();
    }
  };

  const onRowClick = async ({ id: userId }) => {
    if (userId) {
      await router.push(`/users/${userId || ''}`);
    }
  };

  const usersWithStatus: UserWithStatus[] = useMemo(
    () =>
      users.map((user) => {
        const acceptedInvite = user?.userInvites?.find((invite) => invite.status === 'accepted');
        const pendingInvite = user?.userInvites?.find((invite) => invite.status === 'pending');
        const pendingJoinOrgRequest = user?.joinOrgRequests?.find((request) => request.status === 'pending');
        const pendingCreateOrgRequest = user?.createOrgRequests?.find((request) => request.status === 'pending');
        if (user.organization) {
          if (acceptedInvite) {
            return {
              ...user,
              status: 'Verified',
              memberSince: new Date(acceptedInvite.updatedAt).toLocaleDateString(),
            };
          } else {
            return { ...user, status: 'Verified', memberSince: new Date(user.createdAt).toLocaleDateString() };
          }
        } else if (pendingInvite) {
          if (pendingInvite.isNewUser) {
            return {
              ...user,
              status: 'Invite Pending',
              memberSince: 'N/A',
            };
          }

          return {
            ...user,
            status: 'Invite Pending',
            memberSince: new Date(user.createdAt).toLocaleDateString(),
          };
        } else if (pendingJoinOrgRequest || pendingCreateOrgRequest) {
          return {
            ...user,
            status: 'Verification Pending',
            memberSince: new Date(user.createdAt).toLocaleDateString(),
          };
        } else {
          return {
            ...user,
            status: 'Unverified',
            memberSince: new Date(user.createdAt).toLocaleDateString(),
          };
        }
      }),
    [users],
  );

  const columns: Column[] = useMemo(() => {
    return [
      {
        key: 'preferences.email',
        header: 'Email',
      },
      {
        key: 'preferences.first',
        header: 'First Name',
      },
      {
        key: 'preferences.last',
        header: 'Last Name',
      },
      {
        key: 'role',
        header: 'Role',
        customCellRenderer: (row) => (row.role == 'member' ? 'Member' : 'Admin'),
      },
      {
        key: 'status',
        header: 'Status',
        customCellRenderer: (props: UserWithStatus) => {
          let color: TagColors = 'gray';
          if (props.status === 'Verified') {
            color = 'green';
          } else if (props.status === 'Invite Pending' || props.status === 'Verification Pending') {
            color = 'yellow';
          }
          return <Tag color={color}>{props.status}</Tag>;
        },
      },
      {
        key: 'organization.name',
        header: 'Organization',
        hidden: columnsToHide?.includes('organization.name'),
      },
      {
        key: 'memberSince',
        header: 'Account Created',
        cellAlignment: 'left',
      },
      {
        key: 'edit',
        cellAlignment: 'right',
        disableSort: true,
        customCellRenderer: (props: UserWithStatus) => {
          if (props.status == 'Invite Pending') {
            return (
              <div className="flex gap-2">
                {hasPermission(PermissionName['edit.organization.resendInvite']) && (
                  <PrimaryButton
                    onClick={() => {
                      setUserIdToResendInvite(props.id);
                      openResendInviteModal();
                    }}
                  >
                    Resend Invite
                  </PrimaryButton>
                )}
                {hasPermission(PermissionName['edit.organization.inviteSameOrg']) && (
                  <DangerButton
                    onClick={() => {
                      setUserIdToCancelInvite(props.id);
                      openCancelInviteModal();
                    }}
                  >
                    Cancel Invite
                  </DangerButton>
                )}
              </div>
            );
          } else {
            return (
              <div className="flex gap-2">
                {hasPermission(PermissionName['edit.user.delete']) && (
                  <PrimaryButton
                    onClick={() => {
                      setUserIdToResetPassword(props.id);
                      openResetPasswordModal();
                    }}
                  >
                    Reset Password
                  </PrimaryButton>
                )}
                {(hasPermission(PermissionName['edit.user.delete']) || props.id === currentUser.id) && (
                  <DangerButton
                    onClick={() => {
                      setUserIdToDelete(props.id);
                      openDeleteUserModal();
                    }}
                  >
                    Delete
                  </DangerButton>
                )}
              </div>
            );
          }
        },
      },
    ];
  }, [usersWithStatus]);

  const memoizedSortedUsers = useMemo(() => {
    return [...usersWithStatus].sort((a, b) => {
      return recursiveSort(a, b, ['preferences.email']);
    });
  }, [usersWithStatus]);

  return (
    <>
      <Modal
        isOpen={isResendInviteModalOpen}
        onChange={resendInviteModalChange}
        primaryAction={handleResendInvite}
        primaryText="Confirm"
        secondaryAction={closeResendInviteModal}
        secondaryText="Cancel"
        title="Confirm invite resend"
      >
        <div className="pb-4">Are you sure you want to resend an invite?</div>
      </Modal>
      <Modal
        isOpen={isDeleteUserModalOpen}
        onChange={deleteUserModalChange}
        primaryAction={handleDeleteUser}
        primaryText="Confirm"
        secondaryAction={closeDeleteUserModal}
        secondaryText="Cancel"
        title="Confirm account deletion"
      >
        <div className="pb-4">Are you sure you want to delete this user?</div>
      </Modal>
      <Modal
        isOpen={isCancelInviteModalOpen}
        onChange={cancelInviteModalChange}
        primaryAction={handleCancelInvite}
        primaryText="Confirm"
        secondaryAction={closeCancelInviteModal}
        secondaryText="Close"
        title="Confirm invite cancel"
      >
        <div className="pb-4">Are you sure you want to cancel this invite?</div>
      </Modal>
      <Modal
        isOpen={isResetPasswordModalOpen}
        onChange={resetPasswordModalChange}
        primaryAction={handleResetPassword}
        primaryText="Confirm"
        secondaryAction={closeResetPasswordModal}
        secondaryText="Cancel"
        title="Confirm reset password"
      >
        <div className="pb-4">Are you sure you want to reset this user password?</div>
      </Modal>
      <Table
        data={memoizedSortedUsers}
        columns={columns}
        onRowClick={onRowClick}
        ariaLabel="Table showing users"
        globalFilter={globalFilter}
        defaultSortingState={tableInfo?.sortingState}
        onSortingChange={setSortingState}
        defaultPaginationState={tableInfo?.paginationState}
        onPaginationChange={setPaginationState}
      />
    </>
  );
}
