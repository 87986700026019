import { ReactNode } from 'react';
import { Card, CardBody, Tab, Tabs as NextUITabs } from '@nextui-org/react';
import classNames from 'classnames';
import Link from 'next/link';

export interface TabsProps {
  width?: 'full' | 'fit';
  defaultTabKey?: string;
  selectedKey?: string;
  tabs: {
    id: string;
    label: string;
    content: ReactNode;
    href?: string;
  }[];
}

export const Tabs = ({ tabs, defaultTabKey, width, selectedKey }: TabsProps) => {
  return (
    <NextUITabs
      selectedKey={selectedKey}
      defaultSelectedKey={defaultTabKey}
      classNames={{
        base: classNames(width === 'fit' ? 'w-fit' : 'w-full'),
        tabList: classNames(width === 'fit' ? 'w-fit' : 'w-full'),
      }}
      items={tabs}
    >
      {(item) => (
        <Tab key={item.id} title={item.label} {...(item.href && { href: item.href, as: Link })}>
          <Card>
            <CardBody>{item.content}</CardBody>
          </Card>
        </Tab>
      )}
    </NextUITabs>
  );
};
