import classNames from 'classnames';
import { ReactNode } from 'react';

export type HeaderLevel = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
interface BaseTypographyProps {
  as?: HeaderLevel | 'p' | 'span';
  className?: string;
  children: ReactNode;
}

export function BaseTypography(props: BaseTypographyProps) {
  const { as = 'p', className } = props;
  const Component = as;
  return <Component className={classNames(className)} {...props} />;
}
