import { Field, Form } from 'react-final-form';
import { PrimaryButton } from '../../atoms/PrimaryButton/PrimaryButton';
import { TextInput } from '../../atoms/TextInput/TextInput';
import adobePdf from 'public/adobe_pdf.png';
import { z } from 'zod';
import { useState } from 'react';
import iosAppStore from 'public/ios-appstore.svg';
import googlePlayAppStore from 'public/google-play-appstore.png';
import { Link } from '../../atoms/Link/Link';
import { default as NextLink } from 'next/link';
import Image from 'next/image';

const LoginFormSchema = z.object({
  email: z.string().email(),
  password: z.string(),
});

function constructErrors(errors) {
  return errors?.reduce((acc, error) => {
    acc[error.path[0]] = error.message;

    return acc;
  }, {});
}

interface LoginFormProps {
  onLogin: (values: { email: string; password: string }) => void;
}
export function LoginForm(props: LoginFormProps) {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const onSubmit = function onSubmit(values) {
    props.onLogin(values);
    setIsLoggingIn(false);
  };

  const handleValidate = function handleValidate(values) {
    try {
      LoginFormSchema.parse(values);
      setHasErrors(false);
    } catch (error) {
      setHasErrors(true);
      setIsLoggingIn(false);
      return constructErrors(error.errors);
    }
  };
  return (
    <div className="flex flex-col gap-2">
      <h2 className="text-xl">Welcome Back</h2>
      <span className="text-small mb-5 text-gray-500">Log into your account to continue</span>
      <Form
        onSubmit={onSubmit}
        validate={handleValidate}
        render={({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit} className="flex flex-col gap-2 items-center">
            <Field name="email">
              {(props) => (
                <TextInput {...props.input} key="email" label="Email address" placeholder="Enter your email" />
              )}
            </Field>
            <Field name="password">
              {(props) => (
                <TextInput
                  {...props.input}
                  inputType="password"
                  key="password"
                  label="Password"
                  placeholder="Enter your password"
                />
              )}
            </Field>
            <div className="ml-auto">
              <Link href="/forgot-password" variant="text" as={NextLink}>
                Forgot password?
              </Link>
            </div>
            <PrimaryButton
              width="full"
              onClick={() => {
                setIsLoggingIn(true);
                form.submit();
              }}
              disabled={hasErrors || isLoggingIn}
              loading={isLoggingIn}
            >
              {isLoggingIn ? 'Logging in' : 'Login'}
            </PrimaryButton>
          </form>
        )}
      />

      <div className="flex gap-1 justify-center">
        <span>Need to create an account?</span>
        <Link href="/signup" variant="text" as={NextLink}>
          Sign up
        </Link>
      </div>

      <div className="flex justify-center flex-col items-center mt-10 gap-6 mb-5">
        <div className="text-center text-sm">Looking to connect a device? Download the Frio app</div>
        <div className="flex flex-col justify-center items-center sm:flex-row gap-6">
          <Link target="_blank" href="https://apps.apple.com/us/app/frio/id1595668212" as={NextLink}>
            <Image priority={true} height={44} src={iosAppStore} alt={'Download on the iOS App Store'} />
          </Link>
          <Link target="_blank" href="https://play.google.com/store/apps/details?id=co.frio.android.dev" as={NextLink}>
            <Image priority={true} height={44} src={googlePlayAppStore} alt={'Get it on Google Play'} />
          </Link>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <Link href="https://www.frio.co/resources" as={NextLink}>
          <div className="flex gap-0.5 items-center">
            <Image
              src={adobePdf}
              width={24}
              height={24}
              alt="PDF Icon"
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
            Controller Documentation
          </div>
        </Link>
      </div>
    </div>
  );
}
