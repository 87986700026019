import type { ClassValue } from 'clsx';

import clsx from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

import { Size } from './types';

export function getComponentSize(size: Size) {
  switch (size) {
    case 'sm':
      return 'h-12 min-h-12';
    case 'lg':
      return 'h-16 min-h-16';
    case 'md':
    default:
      return 'h-14 min-h-14';
  }
}

const COMMON_UNITS = ['small', 'medium', 'large'];

/**
 * Added alongside NextUI Pro components installation:
 * We need to extend the tailwind merge to include NextUI's custom classes.
 * So we can use classes like `text-small` or `text-default-500` and override them.
 */
const twMerge = extendTailwindMerge({
  extend: {
    theme: {
      opacity: ['disabled'],
      spacing: ['divider'],
      borderWidth: COMMON_UNITS,
      borderRadius: COMMON_UNITS,
    },
    classGroups: {
      shadow: [{ shadow: COMMON_UNITS }],
      'font-size': [{ text: ['tiny', ...COMMON_UNITS] }],
      'bg-image': ['bg-stripe-gradient'],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
